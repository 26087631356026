// import React, { useRef, useState } from "react";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import DropboxChooser from 'react-dropbox-chooser';
// const APP_KEY = "ocxmbrakzrc47np";

// function DropdownComponent() {
//   const fileInputRef = useRef(null);
//   const [showUrlInput, setShowUrlInput] = useState(false);
//   const [urlInput, setUrlInput] = useState("");
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleFileFromDevice = (e) => {
//     fileInputRef.current.click();
//     setAnchorEl(null); // Close the menu after selecting from device
//   };

//   const handleFileFromDropbox = () => {
//     // Placeholder logic for Dropbox
//     setAnchorEl(null); // Close the menu after selecting from Dropbox
//   };

//   const handleFileFromGoogleDrive = () => {
//     // Placeholder logic for Google Drive
//     alert("Placeholder for handling file from Google Drive");
//     setAnchorEl(null); // Close the menu after selecting from Google Drive
//   };

//   const handleFileFromUrl = () => {
//     setShowUrlInput(true);
//     setAnchorEl(null); // Close the menu after selecting from URL
//   };

//   const handleSuccessFromDropbox = (files) => {
//     console.log(files);
//     // Add logic to handle the files from Dropbox
//     setAnchorEl(null); // Close the menu after selecting from Dropbox
//   };

//   const handleUrlSubmit = () => {
//     // Handle the URL input (you can replace this with your logic)
//     alert(`URL submitted: ${urlInput}`);
//     setShowUrlInput(false);
//     setAnchorEl(null); // Close the menu after submitting URL
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <>
//       {/* Hidden file input for handling device file selection */}
//       <input
//         type="file"
//         ref={fileInputRef}
//         style={{ display: "none" }}
//         onChange={() => {}} // Placeholder, actual logic in handleFileSelectionFromDevice
//       />

//       {/* Custom dropdown menu for file sources */}
//       <Button
//         variant="contained"
//         sx={{
//           background: "#727CF5",
//           "&:hover": { background: "#727CF5" },
//           px: "32px",
//           py:"16px",
//           borderRadius:"10px"
//         }}
//         onClick={handleMenuOpen}
//         endIcon={<ArrowDropDownIcon />}
//       >
//         + Choose File
//       </Button>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//       >
//         <MenuItem onClick={handleFileFromDevice}>From Device</MenuItem>
//         <MenuItem onClick={handleFileFromDropbox}>
//           {/* Use the DropboxChooser component */}
//           <DropboxChooser
//             appKey={APP_KEY}
//             success={handleSuccessFromDropbox}
//             cancel={() => setAnchorEl(null)}
//           >
//             <span>From Dropbox</span>
//           </DropboxChooser>
//         </MenuItem>
//         <MenuItem onClick={handleFileFromGoogleDrive}>
//           From Google Drive
//         </MenuItem>
//         <MenuItem onClick={handleFileFromUrl}>From URL</MenuItem>
//       </Menu>

//       {/* URL Input Section */}
//       {showUrlInput && (
//         <div className="url-input-section">
//           <input
//             type="text"
//             placeholder="Enter URL"
//             value={urlInput}
//             onChange={(e) => setUrlInput(e.target.value)}
//           />
//           <button onClick={handleUrlSubmit}>Submit URL</button>
//         </div>
//       )}
//     </>
//   );
// }
// export default DropdownComponent;
// work on ........
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { motion, AnimatePresence } from "framer-motion";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropboxChooser from "react-dropbox-chooser";

// const APP_KEY = "ocxmbrakzrc47np";

function DropdownComponent() {
  
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  // const [showUrlInput, setShowUrlInput] = useState(false);
  // const [urlInput, setUrlInput] = useState("");
  // const [uploadProgress, setUploadProgress] = useState(0);


  const handelFileInputClick=()=>{
    fileInputRef.current.click();
  }

  function handleFileSelectionFromDevice(e) {
    const selectedFile = e.target.files[0];
    alert(`Selected file from device: ${selectedFile.name}`);
    navigate("/uploadingPage", { state: { selectedFile } });
    // handleFileUpload(selectedFile);
    // console.log(selectedFile);
  }

  // function handleFileFromDropbox(files) {
  //   const selectedFile = files[0];
  //   console.log("Selected file from device:", selectedFile);
  //   // navigate('/uploadingPage', { uploadProgress: setUploadProgress });

  //   //     console.log(selectedFile);
  //   //     alert(`Selected file from dropbox: ${selectedFile.name}`);
  //   //     navigate('/uploadingPage', { state: { selectedFile } });
  //   //     console.log(selectedFile);
  //   // >>>>>>> aaeaffabc96800ec92a7f5889ae298323f44a399
  //   // handleFileUpload(selectedFile);
  // }

  // function handleFileFromGoogleDrive() {
  //   alert("Placeholder for handling file from Google Drive");
  //   // You need to implement Google Drive file selection here
  // }

  // function handleFileFromUrl() {
  //   setShowUrlInput(true);
  // }

  // function handleUrlSubmit() {
  //   setShowUrlInput(false);
  //   // Handle URL submission
  // }




  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelectionFromDevice}
        accept="video/*"
      />

      <Button
        component={motion.div}
        whileHover={{
          scale: 1.1,
          transition: { duration: 0.3}
        }}
        whileTap={{ scale: 0.8 }}
        variant="contained"
        sx={{
          background: "#727CF5",
          "&:hover": { background: "#727CF5" },
          px: "32px",
          py: "16px",
          borderRadius: "10px",
          zIndex:"1"
        }}
        onClick={handelFileInputClick}
        endIcon={<ArrowDropDownIcon />}
      >
        + Choose File
      </Button>

      {/* <DropdownButton id="custom-dropdown" title="+ Choose File">
        <Dropdown.Item onClick={() => fileInputRef.current.click()}>From Device</Dropdown.Item>
        <Dropdown.Item>
          <DropboxChooser appKey={APP_KEY} success={handleFileFromDropbox} cancel={() => console.log('Dropbox chooser closed')}>
            <span>From Dropbox</span>
          </DropboxChooser>
        </Dropdown.Item>
        <Dropdown.Item onClick={handleFileFromGoogleDrive}>From Google Drive</Dropdown.Item>
        <Dropdown.Item onClick={handleFileFromUrl}>From URL</Dropdown.Item>
      </DropdownButton>

      {showUrlInput && (
        <div className="url-input-section">
          <input type="text" placeholder="Enter URL" value={urlInput} onChange={(e) => setUrlInput(e.target.value)} />
          <button onClick={handleUrlSubmit}>Submit URL</button>
        </div>
      )} */}
    </>
  );
}

export default DropdownComponent;