import React from "react";
import { Box, Typography, List, ListItem } from "@mui/material";

function TapeRecorderComponents() {
  const data = [
    {
      heading: `Unlimited Voice`,
      content: `Capture as much as you want, and let nothing slip through the cracks.`,
    },
    {
      heading: `Versatility in File Formats`,
      content: `Support for popular formats like mp3, m4a, and 3gp ensures compatibility andconvenience.`,
    },
    {
      heading: `User-Friendly Interface`,
      content: `Effortlessly delete, rename, Edit and share your recordings with afew taps.`,
    },
    {
      heading: `Background Recording`,
      content: `Keep the creativity flowing even when your display is off.`,
    },
    {
      heading: `Share Any time Any where`,
      content: `This voice recorder app ensures that your recordings are at your fingertips share inwhatsapp or mail anywhere. Be it a crucial meeting insight or a spontaneousidea, the ease of access makes every recording a valuable asset.`,
    },
  ];
  return (
    <Box width={"100%"} sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box
        sx={{
          width: { xs: "98%", sm: "98%", md: "50%", lg: "50%" },
          padding:"0.5rem 0% 0.5rem 5%" ,
        }}
      >
        <Typography
          sx={{
            textAlign: { xs: "center", sm: "start", lg: "start" },
            lineHeight: 1.2,
            fontWeight: 800,
            fontSize: { xs: "1.687rem", lg: "2.687rem" },
            // fontSize:{xs:"24px",sm:"2.687rem",md:"2.687rem",lg:"2.687rem"},
            background:
              "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontFamily: "inherit",
            // width:{xs:"90%",sm:"31.75rem",md:"31.75rem",lg:"31.75rem"}
          }}
        >
          Why Choose "Audio Recorder – Tape Recorder"?
        </Typography>
        {/* <Typography
          sx={{
            textAlign: { xs: "center", sm: "start", lg: "start" },
            fontFamily: "inherit",
            fontSize: "1.125rem",
            lineHeight: "1.406rem",
            wordWrap: "break-word",
            mt: "1rem",
            color: "gray",
          }}
        >
          Use our easy and quick voice recorder app as
        </Typography> */}
        <Box>
          {data.map((item, index) => (
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
              }}
            >
              <ListItem
                sx={{
                  display: "list-item",
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {item.heading}:
                </span>
                {item.content}
              </ListItem>
            </List>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          width: { xs: "98%", sm: "98%", md: "50%", lg: "50%" },
          display:"flex",
          justifyContent:"center",
          alignItems:"center"
        }}
      >
        <img
          loading="eager"
          alt="voice Recorder"
          src="../assets/taperecord.svg"
          style={{
            width: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
    </Box>
  );
}

export default TapeRecorderComponents;
