import React from "react";
import { Box } from "@mui/material";

function VideoIcon({left}) {
  return (
    <Box
      sx={{
        position: "absolute",
        // border: "1px solid black",
        // borderRadius:"50%",
        // borderRadius: "5px",
        // display: "flex",
        // justifyContent: "space-around",
        // alignItems:"center",
        // p: "5px 10px",
        // background:"linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 255, 0, 1))"
      }}
    //   left={"-45px"}
    left={left}
    >
      {/* <Box>
        <Box
          sx={{
            border: "1px solid black",
            borderRadius: "50%",
            height: "5px",
            width: "5px",
            // mt: "2px",
          }}
        ></Box>
        <Box
          sx={{
            border: "1px solid black",
            borderRadius: "50%",
            height: "5px",
            width: "5px",
            // mt: "2px",
          }}
        ></Box>
        <Box
          sx={{
            border: "1px solid black",
            borderRadius: "50%",
            height: "5px",
            width: "5px",
            // mt: "2px",
          }}
        ></Box>
      </Box> */}
      {/* <FlexCenter sx={{width:"16px",height:"15px",m:"2px"}}> */}
      <img
        src="../assets/video-icon.svg"
        alt=""
        style={{
          color: "white",
          height: "25px",
          width: "50px",
          objectFit: "cover",
          borderRadius: "5px",
          // border:"2px solid rgb(238,174,202)"
        }}
      />
      {/* </FlexCenter> */}
      {/* <Box>
        <Box
          sx={{
            border: "1px solid black",
            borderRadius: "50%",
            height: "5px",
            width: "5px",
            // mt: "2px",
          }}
        ></Box>
        <Box
          sx={{
            border: "1px solid black",
            borderRadius: "50%",
            height: "5px",
            width: "5px",
            // mt: "2px",
          }}
        ></Box>
        <Box
          sx={{
            border: "1px solid black",
            borderRadius: "50%",
            height: "5px",
            width: "5px",
            // mt: "2px",
          }}
        ></Box>
      </Box> */}
    </Box>
  );
}

export default VideoIcon;
