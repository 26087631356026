import styles from "./GroupComponent.module.css";
import style from "./Groupglobal.css";

const GroupComponent = () => {
  return (
    <div className={styles.rectangleParent}>
      <div className={styles.frameChild} />
      <div className={styles.frameWrapper}>
        <div className={styles.backgroundCompleteParent}>
          <img
            className={styles.backgroundCompleteIcon}
            alt=""
            src="../images/background-complete.svg"
          />
          <img className={styles.wallIcon} alt="" src="../images/wall.svg" />
          <img
            className={styles.character2Icon}
            loading="lazy"
            alt=""
            src="../images/character-2.svg"
          />
          <img
            className={styles.character1Icon}
            alt=""
            src="../images/character-1.svg"
          />
          <img
            className={styles.plantIcon}
            loading="lazy"
            alt=""
            src="../images/plant.svg"
          />
          <div className={styles.group}>
            <img
              className={styles.groupIcon}
              alt=""
              src="../images/group-1.svg"
            />
            <div className={styles.group1}>
              <img
                className={styles.groupIcon1}
                alt=""
                src="../images/group-2.svg"
              />
              <img
                className={styles.groupIcon2}
                alt=""
                src="../images/group-3.svg"
              />
              <img
                className={styles.groupIcon3}
                alt=""
                src="../images/group-4.svg"
              />
              <img
                className={styles.groupIcon4}
                alt=""
                src="../images/group-5.svg"
              />
              <img
                className={styles.groupIcon5}
                alt=""
                src="../images/group-6.svg"
              />
              <img
                className={styles.groupIcon6}
                alt=""
                src="../images/group-7.svg"
              />
              <img
                className={styles.groupIcon7}
                alt=""
                src="../images/group-8.svg"
              />
              <img
                className={styles.groupIcon8}
                alt=""
                src="../images/group-9.svg"
              />
              <img
                className={styles.groupIcon9}
                alt=""
                src="../images/group-10.svg"
              />
              <img
                className={styles.groupIcon10}
                alt=""
                src="../images/group-11.svg"
              />
              <img
                className={styles.groupIcon11}
                alt=""
                src="../images/group-12.svg"
              />
              <img
                className={styles.groupIcon12}
                alt=""
                src="../images/group-13.svg"
              />
              <img
                className={styles.groupIcon13}
                alt=""
                src="../images/group-14.svg"
              />
              <img
                className={styles.groupIcon14}
                alt=""
                src="../images/group-15.svg"
              />
              <img
                className={styles.groupIcon15}
                alt=""
                src="../images/group-16.svg"
              />
              <img
                className={styles.groupIcon16}
                alt=""
                src="../images/group-17.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.podcastingAndContentCreatioWrapper}>
        <div className={styles.podcastingAndContent}>
          Podcasting and Content Creation
        </div>
      </div>
      <div className={styles.producePodcastsAudio}>
        Produce podcasts, audio blogs, or other content by recording
        conversations, monologues, or scripted content.
      </div>
    </div>
  );
};

export default GroupComponent;
