import React from "react";
import { Box as MuiBox, styled } from "@mui/material";

const StyledBox = styled(MuiBox)({
  height: "100px",
  width: "100px",
});

const StyledGroup = styled(MuiBox)({
  height: "188px",
  left: 0,
  // position: "fixed",
  top: 0,
  transform: "rotate(-147.5deg)",
  width: "50px",
});

const StyledOverlapGroup = styled(MuiBox)({
  height: "234px",
  left: "28px",
  position: "relative",
  top: "-42px",
  width: "300px",
});

const StyledVector = styled("img")({
  height: "128px",
  left: "49px",
  position: "absolute",
  top: "54px",
  transform: "rotate(147.5deg)",
  width: "135px",
});

const StyledPolygon = styled("img")({
  height: "27px",
  left: "21px",
  position: "absolute",
  top: "115px",
  transform: "rotate(147.5deg)",
  width: "55px",
});

const Box = () => {
  return (
    <StyledBox>
      <StyledGroup>
        <StyledOverlapGroup>
          <StyledVector alt="Vector" src="../assets/convert_arrow.svg" />
          <StyledPolygon alt="Polygon" src="../assets/Polygon.svg" />
        </StyledOverlapGroup>
      </StyledGroup>
    </StyledBox>
  );
};

export default Box;
