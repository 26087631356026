import React from 'react';
import { Box,Button as MuiButton, styled,useMediaQuery } from '@mui/material';

const Button = styled(MuiButton)`
  --primary-color: #645bff;
  --secondary-color: #fff;
  --hover-color: #111;
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  box-sizing: border-box;
  border: 0;
  border-radius: 10px;
  color: var(--secondary-color);
  // padding: 0.8em 1.8em;
  background: var(--primary-color);
  display: flex;
  transition: 0.2s background;
  align-items: center;
  gap: 0.6em;
  font-weight: bold;
  width:150px;
  // margin-left: 120px;
  ${({ theme }) => `
    margin-left: ${useMediaQuery("(min-width:900px)") ? '120px' : '35px'};
  `}
  ${({ theme }) => `
  padding: ${useMediaQuery("(min-width:900px)") ? '0.8em 1.8em' : '0.2em 1rem'};
  `}


  &:hover {
    background-color: var(--hover-color);
  }

  .arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow {
    margin-top: 1px;
    width: var(--arrow-width);
    background: var(--primary-color);
    height: var(--arrow-stroke);
    position: relative;
    transition: 0.2s;
  }

  &:hover .arrow {
    background: var(--secondary-color);
  }

  &:hover .arrow:before {
    right: 0;
  }

  .arrow::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border: solid var(--secondary-color);
    border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
    display: inline-block;
    top: -3px;
    right: 3px;
    transition: 0.2s;
    padding: 3px;
    transform: rotate(-45deg);
  }
`;

const CustomButton = ({...props }) => {
  return (
    <Button {...props}>
      Sign Up
      <Box className="arrow-wrapper">
        <Box className="arrow"></Box>
      </Box>
    </Button>
  );
};

export default CustomButton;
