import 'bootstrap/dist/css/bootstrap.min.css';
 import { CssBaseline } from '@mui/material';
import { BrowserRouter,Routes, Route } from "react-router-dom";
import VideoCompressor from './pages/videoCompressor';
//import  VideoCompressorPage from './pages/VideoCompressorPage';
import Home from './pages/Home';
import { Box } from '@mui/material';
// import Contents from './components/StyledComponents/Contents';
import FileUploadingPage from './pages/uploadingPage';
import TechToolPlus from './pages/TechToolPlus.js';
import VoiceWebsite from './pages/VoiceWebsite';
import LeaveConfirmation from './pages/LeaveConfirmation.js';





function App() {
  return (
    <Box className="App" >
      <BrowserRouter>
          <CssBaseline/>
          <Routes>
           <Route path="/" element={<Home/>} />
           {/* <Route path="/" element={<TechToolPlus/>} /> */}
           <Route path="/videocompressor" element={<VideoCompressor/>} />
             {/* <Route path="/VideoCompressorPage" element={<FileUploadingPage/>}/> */}
             <Route path="/uploadingPage" element={<FileUploadingPage />} /> 
             <Route path="/VoiceRecorder" element={<VoiceWebsite />} />
             {/* <Route path="/home" element={<Home />} /> */}
          </Routes>
      </BrowserRouter>
      <LeaveConfirmation />
    </Box>
  );
}

export default App;
