import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import TheatersIcon from '@mui/icons-material/Theaters';
import ShareIcon from '@mui/icons-material/Share';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';

const getBackgroundColor = (index) => {
    // Logic to calculate background color based on index
    // For example:
    if (index === 0) {
      return "#ff0000"; // Red for index 0
    } else if (index === 1) {
      return "#00ff00"; // Green for index 1
    } else {
      return "#175cff"; // Default color for other indices
    }
  };

function WhyChooseUsComponents() {
  const data = [
    {
      heading: `User-Friendly Interface`,
      icons: (
        <MobileFriendlyIcon
          fontSize="large"
          sx={{
            color: "#fff",
          }}
        />
      ),
      content: `Tech tool Video Compressor is not just any video resizer tool; it boasts an intuitive interface designed for users of all skill levels. Enjoy a seamless compression experience without
      the need for extensive technical know-how.`,
    },
    {
      heading: `Save & Share with Ease`,
      icons: (
        <ShareIcon
          fontSize="large"
          sx={{
            color: "#fff",
          }}
        />
      ),
      content: `Once your videos are compressed or edited, easily view them. Compare different versions, and with just one tap, share your content on popular platforms like Instagram, Facebook,
      WhatsApp, emails, and more on the go.`,
    },
    {
      heading: `Versatile Compression`,
      icons: (
        <TheatersIcon
          fontSize="large"
          sx={{
            color: "#fff",
          }}
        />
      ),
      content: `Whether you're uploading to social networks, sending videos via email, or storing in the cloud, Tech tool video compressor is tailored to meet your diverse video resizer needs. Compress
      single or multiple files at once with our 4K compressor.`,
    },
  ];

  return (
    <Box mt={"2rem"}>
      <Typography
        sx={{
          textAlign: "center",
          lineHeight: 1.2,
          fontWeight: 800,
          fontSize: { xs: "1.687rem", lg: "2.687rem" },
          // fontSize:{xs:"24px",sm:"2.687rem",md:"2.687rem",lg:"2.687rem"},
          background:
            "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontFamily: "inherit",
          // width:{xs:"90%",sm:"31.75rem",md:"31.75rem",lg:"31.75rem"}
        }}
      >
        Why Choose Us ?
      </Typography>
      <Box
        sx={{
          padding: "1rem 2%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "1rem",
          mt: "3rem",
        }}
      >
        {data.map((item, index) => (
          <Box
            key={index}
            sx={{
              // border: "2px solid rgba(101, 101, 101, .1)",
              width: "340px",
              // borderRadius: "1rem",
            //   height: "18rem",
              margin: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 20px 20px 0 rgba(2,19,79,.09)",
              },
            }}
          >
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "65px",
                  height: "65px",
                  lineHeight: "65px",
                  textAlign: "center",
                //   backgroundColor: "#175cff",
                backgroundColor:getBackgroundColor(index),
                  borderRadius: "1rem",
                }}
              >
                {item.icons}
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "inherit",
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  lineHeight: "1.406rem",
                  letterSpacing: ".14px",
                  pt: "2.5rem",
                }}
              >
                {item.heading}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "inherit",
                  lineHeight: "1.406rem",
                  letterSpacing: ".14px",
                  pt: "1rem",
                }}
              >
                {item.content}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default WhyChooseUsComponents;
