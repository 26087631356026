import React from "react";
import { Box, Typography, useMediaQuery, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import VideoComponents from "./VideoComponents";
import DoneComponents from "./DoneComponents";
import FlexCenter from "../StyledComponents/FlexCenter";

const Item = (props) => (
  <Paper
    sx={{
      justifyContent: "flex-start",
      padding: "16px 15%",
      borderRadius: "0px",
      ...props.sx,
    }}
  >
    {props.children}
  </Paper>
);

//   const styles = {
//     root: {
//       width: "100%",
//       height: "100%",
//       backgroundImage: `url(${background})`,
//       backgroundSize: "cover",
//       backgroundPosition: "center",
//       padding: "3% 5%",
//     },
//     text: {
//       color: "white",
//       textAlign: "center",
//     },
//   };

const ComprssedComponents = ({
  filename,
  originalfilesize,
  compressedfilesize,
  finalUrl,
}) => {
  // console.log(`final-url:${finalUrl}`)
  const theme = useTheme();
  return (
    <FlexCenter>
      {/* <Header /> */}
      <Box
        sx={{
          width: "75%",
          height: "30%",
          background: "white",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
          borderRadius: "15px",
          flexWrap: "nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem 0px 1rem 1rem",
        }}
      >
        <Grid
          container
          // direction="row"
          // justifyContent="flex-start"
          // alignItems="flex-end"
          width="100%"
          height="100%"
          // position="absolute"
          box-sizing="border-box"
        >
          {/* <Grid item lg={12} border="none">
            <Item
              sx={{
                borderTopLeftRadius: "35px",
                border: "none",
                // background: "rgba(114, 124, 245, 0.10)",
                color: "rgba(0, 0, 0, 0.50)",
                boxShadow: "none",
                // width:"200px",
                // padding:"0rem",
                padding: "1rem 0rem 0px 0rem",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#FF585C",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Compressed by 50%
              </Typography>
            </Item>
          </Grid> */}
          <Grid item lg={6} border="none">
            <Item
              sx={{
                // borderTopLeftRadius: "35px",
                border: "none",
                // background: "rgba(114, 124, 245, 0.10)",
                color: "rgba(0, 0, 0, 0.50)",
                boxShadow: "none",
                // width:"200px",
                // padding:"0rem",
                padding: "1rem 0rem 0px 1rem",
              }}
            >
              <Box>
                <Box>
                  <VideoComponents videoUrl={finalUrl} />
                </Box>
              </Box>
            </Item>
          </Grid>

          <Grid item lg={6} border="none">
            <Item
              sx={{
                // borderTopRightRadius: isNonMobileScreen ? "35px" : "0px",
                border: "none",
                boxShadow: "none",
                // background: "rgba(114, 124, 245, 0.10)",
                // color: "rgba(0, 0, 0, 0.50)",
                padding: "1rem 0rem",
              }}
            >
              <DoneComponents
                filename={filename}
                originalfilesize={originalfilesize}
                compressedfilesize={compressedfilesize}
                finalUrl={finalUrl}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </FlexCenter>
  );
};

export default ComprssedComponents;
