import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "../components/header";
import ChoseFile from "../components/ChoseFile";
import FigmaDiv from "../components/StyledComponents/FigmaDiv";
import Footer from "../components/footer";
import Blog from "../components/blog";
// import PricingPlan from "../components/pricingPlan";
import Arrow from "../components/StyledComponents/Arrow";
// import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import AddMoreButton from "../components/StyledComponents/AddMoreButton";

function VideoCompressor() {
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");



  return (
    <Box
      minWidth="100%"
      sx={{
        background:
          "linear-gradient(180deg, rgba(187, 95, 245, 0.15) 0%, rgba(114, 124, 245, 0.02) 100%)",
      }}
    >
      <Header />
      <Box 
       component={motion.div} 
       variants={{
        hidden:{opacity:0,y:75},
        visible:{opacity:1,y:0}
       }}
       initial="hidden"
       animate="visible"
       transition={{duration:0.5,delay:0.25}}
       pt={"6.5rem"}
      >
        <Box
          width="100%"
          display={isNonMobileScreen ? "flex" : "block"}
          padding={"0.5rem 3%"}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
              // height:{xs:"300px",sm:"300px",md:"350px",lg:"350px"},
              display: "flex",
              justifyContent: "center",
              // alignItems:"center",
              // mt:1
            }}
          >
            <FigmaDiv />
          </Box>

          <Box mt={isNonMobileScreen ? "60px" : "120px"}>
            <ChoseFile />
            <Box display={isNonMobileScreen ? "block" : "none"}>
              <Arrow />
            </Box>
          </Box>
        </Box>
        <Box>
          <Blog />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

export default VideoCompressor;
