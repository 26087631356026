import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.fRAMEFrame}>
        <div className={styles.soundCaptureSymbol}>
          <div className={styles.divmuigridRoot}>
            <div className={styles.audioRecorder}>Audio Recorder</div>
            <div className={styles.audioManipulationSet}>
              <div className={styles.videoEditorTools}>
                <img
                  className={styles.toolSbCutVideosvgIcon}
                  loading="lazy"
                  alt=""
                  src="../images/toolsbcutvideosvg.svg"
                />
                <img
                  className={styles.toolSbReverseVideosvgIcon}
                  loading="lazy"
                  alt=""
                  src="../images/toolsbreversevideosvg.svg"
                />
                <img
                  className={styles.toolSbMuteVideosvgIcon}
                  loading="lazy"
                  alt=""
                  src="../images/toolsbmutevideosvg.svg"
                />
                <img
                  className={styles.toolSbCutAudiosvgIcon}
                  loading="lazy"
                  alt=""
                  src="../images/toolsbcutaudiosvg.svg"
                />
              </div>
              <div className={styles.reverseAudio}>
                <div className={styles.cutAudio}>Cut Audio</div>
                <div className={styles.reverseAudio1}>Reverse Audio</div>
                <div className={styles.muteAudio}>Mute Audio</div>
                <div className={styles.cutAudio1}>Cut Audio</div>
              </div>
            </div>
          </div>
          <div className={styles.mergeAudios}>
            <div className={styles.mergeAudio}>Merge Audio</div>
            <div className={styles.videoImposeAudio}>
              <img
                className={styles.toolSbRotateVideosvgFillIcon}
                alt=""
                src="../images/toolsbrotatevideosvg-fill.svg"
              />
              <div className={styles.rotateAudio}>Rotate Audio</div>
            </div>
            <div className={styles.videoImposeAudio1}>
              <img
                className={styles.toolSbCropVideosvgFillIcon}
                alt=""
                src="../images/toolsbcropvideosvg-fill.svg"
              />
              <div className={styles.cropAudio}>Crop Audio</div>
            </div>
            <div className={styles.flipVideo}>
              <img
                className={styles.toolSbVideoImposeAudiosvgIcon}
                loading="lazy"
                alt=""
                src="../images/toolsbvideoimposeaudiosvg.svg"
              />
              <div className={styles.addMusicTo}>Add music to video</div>
            </div>
            <div className={styles.qRCodeCreator}>
              <img
                className={styles.toolSbMergeAudiosvgFillIcon}
                loading="lazy"
                alt=""
                src="../images/toolsbmergeaudiosvg-fill.svg"
              />
              <div className={styles.mergeAudio1}>Merge audio</div>
            </div>
          </div>
          <div className={styles.scanISBN}>
            <div className={styles.scanWifiAndConnect}>
              <div className={styles.resizeVideo}>
                <div className={styles.resizeVideo1}>Resize video</div>
              </div>
              <div className={styles.videoMaker}>
                <img
                  className={styles.toolSbVideoMakersvgIcon}
                  alt=""
                  src="../images/toolsbvideomakersvg.svg"
                />
                <div className={styles.videoMaker1}>Video maker</div>
              </div>
              <div className={styles.videoMaker2}>
                <img
                  className={styles.toolSbFlipVideosvgIcon}
                  alt=""
                  src="../images/toolsbflipvideosvg.svg"
                />
                <div className={styles.flipVideo1}>Flip video</div>
              </div>
              <div className={styles.videoMaker3}>
                <img
                  className={styles.toolSbAdjustVideosvgIcon}
                  alt=""
                  src="../images/toolsbadjustvideosvg.svg"
                />
                <div className={styles.adjustVideo}>Adjust video</div>
              </div>
            </div>
          </div>
          <div className={styles.videoMakerFrameWrapper}>
            <div className={styles.videoMakerFrame}>
              <div className={styles.videoCompressor}>Video Compressor</div>
              <div className={styles.mPConverterFrame}>
                <div className={styles.mp4Converter}>MP4 Converter</div>
                <div className={styles.movToMp4}>MOV to MP4</div>
                <div className={styles.videoConverter}>Video Converter</div>
                <div className={styles.speedVideo}>Speed video</div>
              </div>
            </div>
          </div>
          <div className={styles.soundCaptureSymbolInner}>
            <div className={styles.qrCodeCreatorParent}>
              <div className={styles.qrCodeCreator}>QR Code Creator</div>
              <div className={styles.scanUrlscanWebsiteParent}>
                <div className={styles.scanUrlscanWebsite}>
                  Scan URL/Scan Website
                </div>
                <div className={styles.scanProductsScan}>
                  Scan Products, Scan ISBN
                </div>
                <div className={styles.scanWifiAnd}>Scan wifi and Connect</div>
                <div className={styles.scanLocationAnd}>
                  Scan Location and Directions
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.fRAMEFrameInner}>
          <div className={styles.contactInfoParent}>
            <div className={styles.contactInfo}>Contact Info</div>
            <div className={styles.emailPhoneFrame}>
              <div className={styles.mailFrame}>
                <div className={styles.mailsvgFillParent}>
                  <img
                    className={styles.mailsvgFillIcon}
                    alt=""
                    src="../images/mailsvg-fill.svg"
                  />
                  <img
                    className={styles.phonesvgIcon}
                    alt=""
                    src="../images/phonesvg.svg"
                  />
                </div>
              </div>
              <div className={styles.contactraysaarcomFrame}>
                <div className={styles.contactraysaarcom}>
                  contact@raysaar.com
                </div>
                <div className={styles.termsOfUse}>+971 123456789</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerMedia}>
        <div className={styles.frameMain}>
          <div className={styles.separator} />
          <div className={styles.separatorLine}>
            <div className={styles.audioRecorderAllContainer}>
              <span className={styles.audioRecorderAll}>
                © 2024 Audio Recorder. All rights reserved.
              </span>
            </div>
            <div className={styles.aboutUsLink}>
              <div className={styles.aboutUs}>About Us</div>
              <div className={styles.termsOfUse1}>Terms of Use</div>
              <div className={styles.privacyPolicy}>Privacy Policy</div>
              <div className={styles.help}>Help</div>
              <div className={styles.termsLink}>
                <img className={styles.svgIcon} alt="" src="/svg.svg" />
                <div className={styles.english}>English</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
