import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
// import Loader from "./Loader";
import CustomLoader from "./CustomLoader";
import CompressingProgressBar from "./CompressingProgressBar";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import FlexCenter from "../StyledComponents/FlexCenter";
import VideoIcon from "./VideoIcon";

const Item = (props) => (
  <Paper
    sx={{
      justifyContent: "flex-start",
      padding: "16px 15%",
      borderRadius: "0px",
      ...props.sx,
    }}
  >
    {props.children}
  </Paper>
);

function CompressingComponents({
  multipleFilesSelected,
  fileUploadingProgress,
  fileProgress,
  uploadedFiles,
  handleRemoveFile,
}) {
  const theme = useTheme();



  return (
    <FlexCenter>
      <Box
        sx={{
          width: "75%",
          height: "75%",
          background: "white",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
          flexWrap: "nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "3rem 0px",
        }}
      >
        <Box
          sx={{
            width: "90%",
            // minHeight: isNonMobileScreen ? "60%" : "85%",
            // borderTopLeftRadius: isNonMobileScreen ? "33px" : "22px",
            // borderTopRightRadius: isNonMobileScreen ? "33px" : "22px",
            border: "1px rgba(0, 0, 0, 0.25) solid",
            borderRadius: "5px",
          }}
        >
          <Grid
            container
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-end"
            width="100%"
            height="100%"
            // position="absolute"
            box-sizing="border-box"
          >
            {/* akash */}
            {uploadedFiles.length > 0 &&
              uploadedFiles.map((file, index) => (
                <React.Fragment key={index}>
                  <Grid item lg={8} border="none">
                    <Item
                      sx={{
                        borderTopLeftRadius: "35px",
                        border: "none",
                        // background: "rgba(114, 124, 245, 0.10)",
                        color: "rgba(0, 0, 0, 0.50)",
                        boxShadow: "none",
                        // width:"200px",
                        // padding:"8px 0px 0px 12px"
                      }}
                    >
                      <Box sx={{ position: "relative" }}>
                      <VideoIcon left={"-55px"} />
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                          variant={theme.typography.h5}
                        >
                          {file.name}
                        </Typography>
                      </Box>
                    </Item>
                  </Grid>

                  <Grid item lg={4} border="none">
                    <Item
                      sx={{
                        borderTopRightRadius: "33px",
                        border: "none",
                        boxShadow: "none",
                        // background: "rgba(114, 124, 245, 0.10)",
                        // color: "rgba(0, 0, 0, 0.50)",
                      }}
                    >
                      {/* animation */}
                      <img
                        src="../assets/compressed.svg"
                        alt=""
                        style={{
                          color: "white",
                          height: "35px",
                          width: "150px",
                          objectFit: "cover",
                        }}
                      />
                      {/* end animation */}
                    </Item>
                  </Grid>

                  {/* <Grid item lg={1} border="none">
                    <Item
                      sx={{
                        borderTopRightRadius: "33px",
                        color: "rgba(0, 0, 0, 0.50)",
                        border: "none",
                        boxShadow: "none",
                      }}
                      // onClick={handleRemoveFile(index)}
                      // onClick={() => removeFile(index)}
                    >
                      <Box>
                        <CancelRoundedIcon
                          sx={{
                            fontSize: "26px",
                            transition: "transform 0.3s ease-in-out",
                            "&:hover": {
                              cursor: "pointer",
                              color: "red",
                              transform: "scale(1.2)",
                            },
                          }}
                          // onClick={() => handleRemoveFile(index)}
                        />
                      </Box>
                    </Item>
                  </Grid> */}
                </React.Fragment>
              ))}
            {/* end akash */}

            {multipleFilesSelected.length > 0 &&
              multipleFilesSelected.map((file, index) => (
                <React.Fragment key={index}>
                  <Grid item lg={8} border="none">
                    <Item
                      sx={{
                        borderTopLeftRadius: "35px",
                        border: "none",
                        // background: "rgba(114, 124, 245, 0.10)",
                        color: "rgba(0, 0, 0, 0.50)",
                        boxShadow: "none",
                        // width:"200px",
                        // padding:"8px 0px 0px 12px"
                      }}
                    >
                      <Box  sx={{ position: "relative" }}>
                        <VideoIcon left={"-55px"} />
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                          variant={theme.typography.h5}
                        >
                          {file.name}
                        </Typography>
                      </Box>
                    </Item>
                  </Grid>

                  <Grid item lg={3} border="none">
                    <Item
                      sx={{
                        // borderTopRightRadius: isNonMobileScreen ? "35px" : "0px",
                        border: "none",
                        boxShadow: "none",
                        // background: "rgba(114, 124, 245, 0.10)",
                        // color: "rgba(0, 0, 0, 0.50)",
                      }}
                    >
                      {/* <CompressingProgressBar
                        progress={fileUploadingProgress[file.name]}
                      />

                      <CompressingProgressBar
                        progress={fileProgress[file.name]}
                      /> */}

                      {/* {fileUploadingProgress[file.name] !== undefined ? (
                        <CompressingProgressBar
                          progress={fileUploadingProgress[file.name] || 0} 
                        />
                      ) : (
                        <CustomLoader />
                      )} */}
                      {fileProgress[file.name] !== undefined ? (
                        <CompressingProgressBar
                          progress={fileProgress[file.name] || 0} 
                        />
                      ) : (
                        <CustomLoader />
                      )}
                    </Item>
                  </Grid>

                  <Grid item lg={1} border="none">
                    <Item
                      sx={{
                        borderTopRightRadius: "33px",
                        color: "rgba(0, 0, 0, 0.50)",
                        border: "none",
                        boxShadow: "none",
                      }}
                      // onClick={handleRemoveFile(index)}
                      // onClick={() => removeFile(index)}
                    >
                      <Box>
                        <CancelRoundedIcon
                          sx={{
                            fontSize: "26px",
                            transition: "transform 0.3s ease-in-out",
                            "&:hover": {
                              cursor: "pointer",
                              color: "red",
                              transform: "scale(1.2)",
                            },
                          }}
                          onClick={() => handleRemoveFile(index)}
                        />
                      </Box>
                    </Item>
                  </Grid>
                </React.Fragment>
              ))}
            <Grid item lg={8} border="none">
              <Item
                sx={{
                  background: "rgba(114, 124, 245, 0.10)",
                  color: "rgba(0, 0, 0, 0.50)",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                Compressed {uploadedFiles.length} Files
              </Item>
            </Grid>
            <Grid item lg={4} border="none">
              <Item
                sx={{
                  // borderTopRightRadius: isNonMobileScreen ? "35px" : "0px",
                  borderBottomRightRadius: "5px",
                  background: "rgba(114, 124, 245, 0.50)",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <Typography
                  variant={theme.typography.h6}
                  sx={{
                    wordWrap: "break-word",
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  please wait...
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </FlexCenter>
  );
}

export default CompressingComponents;
