// import React from "react";
// // import { Box, Typography } from "@mui/material";
// // import FlexBetween from "../StyledComponents/FlexBetween";
// import ReactPlayer from "react-player";

// // function VideoComponents({ videoUrl }) {
// //   const videoRef = useRef(null);
// //   const [isPlaying, setIsPlaying] = useState(false);

// //   useEffect(() => {
// //     if (videoRef.current) {
// //       videoRef.current.src = videoUrl; // Set the video URL when it changes
// //       videoRef.current.load(); // Reload the video element
// //     }
// //   }, [videoUrl]);

// //   const handlePlayPause = () => {
// //     if (videoRef.current.paused) {
// //       videoRef.current.play();
// //       setIsPlaying(true);
// //     } else {
// //       videoRef.current.pause();
// //       setIsPlaying(false);
// //     }
// //   };

// //   const handleVolumeChange = (value) => {
// //     videoRef.current.volume = value / 100;
// //   };

// //   return (
// //     // <Box
// //     // // sx={{
// //     // //   height: "90%",
// //     // //   width: "90%",
// //     // // }}
// //     // >
// //     //   <video
// //     //     ref={videoRef}
// //     //     style={{
// //     //       height: "100%",
// //     //       width: "100%",
// //     //       borderRadius: "10px",
// //     //     }}
// //     //     controls
// //     //   >
// //     //     {/* Use the URL of the compressed video from the server */}
// //     //     <source src="http://localhost:5000/compress-video" type="video/mp4" />
// //     //     Your browser does not support the video tag.
// //     //   </video>
// //     //   {/* akash */}
// //     //   {/* <Box padding="1rem 25%">
// //     //     <FlexBetween>
// //     //       <img
// //     //         style={{
// //     //           height: "40px",
// //     //           width: "40px",
// //     //           borderRadius: "10px",
// //     //           cursor: "pointer",
// //     //         }}
// //     //         alt="Play/Pause"
// //     //         src={isPlaying ? "../assets/pause.svg" : "../assets/play.svg"}
// //     //         onClick={handlePlayPause}
// //     //       />
// //     //       <Box>
// //     //         <Typography>00:00 / 00:35</Typography>
// //     //       </Box>
// //     //       <img
// //     //         style={{
// //     //           height: "40px",
// //     //           width: "40px",
// //     //           borderRadius: "10px",
// //     //         }}
// //     //         alt="Pasted image"
// //     //         src="../assets/sound.svg"
// //     //       />
// //     //     </FlexBetween>
// //     //   </Box> */}
// //     // </Box>
// //   );
// // }

// // export default VideoComponents;

// function VideoComponents({ videoUrl }) {
//   // console.log(`video-url:${videoUrl}`);
//   return (
//     <>
//       <ReactPlayer
//         url={videoUrl}
//         controls={true}
//         width="100%"
//         height="100%"
//       />
//     </>
//   );
// }

// export default VideoComponents;

// import React from 'react';
// import { Card, CardMedia } from '@mui/material';
// import ReactPlayer from 'react-player';

// function VideoComponents({ videoUrl }) {
//   return (
//     <Card sx={{ maxWidth: 345, maxHeight: 194 }} > {/* Adjust the size as needed */}
//       <CardMedia>
//         <ReactPlayer
//           url={videoUrl}
//           controls={true}
//           width='100%'
//           height='100%'
//         />
//       </CardMedia>
//     </Card>
//   );
// }

// export default VideoComponents;


import React from 'react';
import { Card, CardMedia } from '@mui/material';
import ReactPlayer from 'react-player';

function VideoComponents({ videoUrl }) {

  return (
    <Card sx={{ maxWidth: '100%', maxHeight: '100%', borderRadius:"15px" }}> {/* Adjust the size as needed */}
      <CardMedia
        sx={{
          position: 'relative',
          paddingTop: '56.25%',
          // paddingTop: aspectRatio === '16:9' ? '56.25%' : '56.25%', // Adjust padding for aspect ratio
        }}
      >
        <ReactPlayer
          url={videoUrl}
          controls={true}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          width='100%'
          height='100%'
        />
      </CardMedia>
    </Card>
  );
}

export default VideoComponents;
