import React from "react";
import { Box, useMediaQuery, Typography, useTheme, Link } from "@mui/material";
import FlexBetween from "./FlexBetween";
import FlexCenter from "./FlexCenter";

function AppStore() {
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();

  return (
    <Box>
      <FlexCenter>
        <Typography
          sx={{
            color: "black",
            fontSize: "24px",
            fontWeight: "600",
            wordWrap: "break-word",
            textAlign:"center",
          }}
          variant={theme.typography.h3}
        >
          Get it on Mobile
        </Typography>
      </FlexCenter>
      <FlexCenter p={isNonMobile ? "0px" : "1rem 3%"}>
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: "400",
            wordWrap: "break-word",
            textAlign:"center"
          }}
          variant={theme.typography.fontFamily}
        >
          Compress videos directly on your mobile device using our{" "}
          <Link
            href="https://play.google.com/store/apps/details?id=com.raytechnoto.video.compressor.converter&hl=en"
            sx={{ color: "#727CF5", textDecoration: "none" }}
          >
            Android Video Compressor
          </Link>{" "}
          or
          <Link href="https://apps.apple.com/us/app/video-compressor-file-resizer/id6475106894" sx={{ color: "#727CF5", textDecoration: "none" }}>
            {" "}
            iOS Video Compressor
          </Link>
        </Typography>
      </FlexCenter>
      <Box padding="1rem  0px">
        <FlexCenter>
          <FlexBetween gap={2} display={{ xs: "block", md: "flex" }}>
            <Link href="https://apps.apple.com/us/app/video-compressor-file-resizer/id6475106894">
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                mt={isNonMobile ? "0px" : "10px"}
              >
                <img
                  src="../assets/apple.svg"
                  alt=""
                  style={{
                    //   color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "fill",
                  }}
                />
              </Box>
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.raytechnoto.video.compressor.converter&hl=en&g%20l=us"
              sx={{ textDecoration: "none" }}
            >
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                mt={isNonMobile ? "0px" : "10px"}
              >
                <img
                  src="../assets/google.svg"
                  alt=""
                  style={{
                    //   color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "fill",
                  }}
                />
              </Box>
            </Link>
          </FlexBetween>
        </FlexCenter>
      </Box>
    </Box>
  );
}

export default AppStore;
