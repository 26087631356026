import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)({
  fontFamily: 'inherit',
  fontSize: '12px',
  background: 'linear-gradient(to bottom, #4f4dd9 0%, #2b60aa 100%)',
  color: 'white',
  padding: '0.8em 1.2em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  borderRadius: '25px',
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
  },
  '&:active': {
    transform: 'scale(0.95)',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
  },
  '& span': {
    display: 'block',
    marginLeft: '0.4em',
    transition: 'all 0.3s',
  },
  '& svg': {
    width: '18px',
    height: '18px',
    fill: 'white',
    transition: 'all 0.3s',
  },
  '& .svg-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    marginRight: '0.5em',
    transition: 'all 0.3s',
  },
  '&:hover .svg-wrapper': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&:hover svg': {
    transform: 'rotate(360deg)',
  },
});

const AddMoreButton = () => {
  return (
    <StyledButton>
      <div className="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M14 0H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2zm-1 14H3V9h10v5z"></path>
        </svg>
      </div>
      <span>Add More files</span>
    </StyledButton>
  );
};

export default AddMoreButton;
