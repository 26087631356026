import React, { useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useClipboard from "react-use-clipboard";
import "./Speech.css";


const Speech = ({ speechClicked }) => {
    const [textToCopy, setTextToCopy] = useState();
    const [isCopied, setCopied] = useClipboard(textToCopy, {
        successDuration: 1000
    });

    const startListening = () => SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
    const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return null;
    }

    return (
        <>
            {speechClicked && (
                <div className="container">
                    <h2>Speech to Text Converter</h2>
                    <br />
                    {/* <p>A React hook that converts speech from the microphone to text and makes it available to your React components.</p> */}

                    <div className="main-content" onClick={() => setTextToCopy(transcript)}>
                        {transcript}
                    </div>

                    <div className="btn-style">
                        <button onClick={() => {
                            console.log('Copy button clicked');
                            setCopied();
                        }}>
                            {isCopied ? 'Copied!' : 'Copy to clipboard'}
                        </button>
                        <button onClick={() => {
                            console.log('Start Listening button clicked');
                            startListening();
                        }}>Start Listening</button>
                        <button onClick={() => {
                            console.log('Stop Listening button clicked');
                            SpeechRecognition.stopListening();
                        }}>Stop Listening</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Speech;

