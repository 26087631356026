import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import FlexCenter from "./FlexCenter";
import FlexBetween from "./FlexBetween";

const rectangleStyles = (height, top, left, width) => ({
  // background: 'linear-gradient(180deg, rgba(106, 25, 232, 0.25) 0%, rgba(185, 77, 245, 0.25) 100%)',
  borderRadius: "25px",
  height,
  position: "absolute",
  left,
  top,
  width,
});

function FigmaDiv() {
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");

  return (
      <Box
        sx={{
          // backgroundColor: "#ffffff",
          height:isNonMobileScreen ? "280px":"180px",
          width:isNonMobileScreen ? "320px":"260px",
          // borderRadius: "25px",
          // boxShadow: "0px -2px 8px 1px #0000001a",
          position: "relative",
          // zIndex: 1000,
        }}
      >
        <Box
          sx={{
            ...rectangleStyles(isNonMobileScreen ? 280 : 240, 20, 20, isNonMobileScreen ? 320 : 240),
            background:
              "linear-gradient(90deg, rgba(102, 23, 231, 0.7) 0%, rgba(194, 83, 246, 0.7) 100%)",
          }}
        />
        <Box
          sx={{
            ...rectangleStyles(isNonMobileScreen ?280:240, 35, 40, isNonMobileScreen ?280 : 200),
            background:
              "linear-gradient(90deg, rgba(108, 27, 232, 0.5) 0%, rgba(188, 79, 245, 0.5) 100%)",
          }}
        />
        <Box
          sx={{
            ...rectangleStyles(isNonMobileScreen ?280:240, 50, 60, isNonMobileScreen ?240:160),
            background:
              "linear-gradient(180deg, rgba(106, 25, 232, 0.25) 0%, rgba(185, 77, 245, 0.25) 100%)",
          }}
        />
        <Box
          sx={{
            backgroundColor: "#ffffff",
            height:isNonMobileScreen ? "280px" :"240px",
            width:isNonMobileScreen ? "360px" :"280px",
            borderRadius: "25px",
            boxShadow: "0px -2px 8px 1px #0000001a",
            position: "relative",
            zIndex: 1000,
          }}
        >
          <FlexCenter>
            <Box
              sx={{
                backgroundColor: "#e6e6e6",
                height:isNonMobileScreen ? "200px" :"160px",
                width: isNonMobileScreen ? "280px" :"220px",
                borderRadius: "15px",
                position: "relative",
                zIndex: 1000,
                mt: 6,
              }}
            >
              <FlexCenter>
                <Box>
                  <img
                    style={{
                      objectFit: "cover",
                      height: isNonMobileScreen ?"110px":"90px",
                      width: isNonMobileScreen ?"110px":"90px",
                      borderRadius: "5px",
                      position: "relative",
                      top: 30,
                      right: 50,
                    }}
                    src="../assets/pexels-photo-2690323 1.png"
                    alt=""
                  />
                </Box>
              </FlexCenter>

              <FlexBetween
                sx={{ position: "absolute", top:isNonMobileScreen ? 150 :130, left: -20 }}
                gap={1}
              >
                <FlexCenter
                  sx={{
                    backgroundColor: "#ffffff",
                    height:isNonMobileScreen?"36px":"26px",
                    p: 1,
                    width: isNonMobileScreen ?"75px":"65px",
                    borderRadius: "5px",
                    boxShadow: "0px -2px 8px 1px #0000001a",
                    zIndex: 2000,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: 500,
                      fontSize: isNonMobileScreen ?"18px":"14px",
                      textAlign: "center",
                      letterSpacing: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    High
                  </Typography>
                </FlexCenter>
                <FlexCenter
                  sx={{
                    backgroundColor: "#ffffff",
                    height: isNonMobileScreen ?"30px":"20px",
                    width: isNonMobileScreen ?"70px":"60px",
                    p: 1,
                    borderRadius: "5px",
                    boxShadow: "0px -2px 8px 1px #0000001a",
                    zIndex: 2000,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#00000080",
                      fontWeight: 500,
                      fontSize: isNonMobileScreen ?"12px":"10px",
                      textAlign: "center",
                      letterSpacing: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Medium
                  </Typography>
                </FlexCenter>
                <FlexCenter
                  sx={{
                    backgroundColor: "#ffffff",
                    height: isNonMobileScreen ?"30px":"20px",
                    width: isNonMobileScreen ?"70px":"60px",
                    // p: 1,
                    borderRadius: "5px",
                    boxShadow: "0px -2px 8px 1px #0000001a",
                    zIndex: 2000,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#00000080",
                      fontWeight: 500,
                      fontSize: isNonMobileScreen ?"12px":"10px",
                      textAlign: "center",
                      letterSpacing: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Low
                  </Typography>
                </FlexCenter>
                <Box
                  sx={{
                    alignItems: "center",
                    background:
                      "linear-gradient(180deg, rgb(118, 48, 222) 0%, rgb(182, 90, 232) 100%)",
                    borderRadius: "3px",
                    zIndex: 3000,
                    display: "inline-flex",
                    padding: isNonMobileScreen ?"3px 12px":"1px 6px",
                    position: "absolute",
                    bottom: isNonMobileScreen ?"130px":"105px",
                    left: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontWeight: 500,
                      fontSize: "18px",
                      textAlign: "center",
                      letterSpacing: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    3MB
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    background:
                      "linear-gradient(180deg, rgb(118, 48, 222) 0%, rgb(182, 90, 232) 100%)",
                    borderRadius: "3px",
                    zIndex: 3000,
                    display: "inline-flex",
                    padding: isNonMobileScreen ?"3px 12px":"1px 6px",
                    position: "absolute",
                    bottom: isNonMobileScreen ?"153px":"125px",
                    left: isNonMobileScreen ?"170px":"130px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontWeight: 500,
                      fontSize: "18px",
                      textAlign: "center",
                      letterSpacing: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    30MB
                  </Typography>
                </Box>
                <Box
                  sx={{
                    zIndex: 3000,
                    position: "absolute",
                    bottom: "120px",
                    left: "80px",
                  }}
                >
                  <img
                    alt="Vector"
                    src="../assets/Frame.svg"
                    style={{
                      height:isNonMobileScreen ?"128px":"60px",
                      // left: "49px",
                      // position: "absolute",
                      // top: "53px",
                      // transform: "rotate(147.5deg)",
                      width:isNonMobileScreen ?"90px":"65px",
                    }}
                  />
                </Box>
              </FlexBetween>
              <Box>
                <img
                  style={{
                    objectFit: "cover",
                    height:isNonMobileScreen ? "200px":"150px",
                    width: isNonMobileScreen ?"120px":"100px",
                    borderRadius: "5px",
                    position: "absolute",
                    bottom: 20,
                    right: 0,
                  }}
                  src="../assets/pexels-photo-2690323 1.png"
                  alt=""
                />
              </Box>
            </Box>
          </FlexCenter>
        </Box>
      </Box>
  );
}

export default FigmaDiv;
