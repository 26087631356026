import React from "react";
import { Box, Typography, List, ListItem } from "@mui/material";

function VoiceRecorderComponents() {
  const data = [
    {
      heading: `SpeechRecorder`,
      content: `Perfect for capturing interviews,meeting minutes or speeches.`,
    },
    {
      heading: `Audio Notes Recorder`,
      content: `Say goodbye to hand written notes and let your voice do the talking by recording unlimited voice notes.`,
    },
    {
      heading: `MusicRecorder`,
      content: `Preserve your musical creations and spontaneous melodies on the go.`,
    },
  ];
  return (
    <Box
      width={"100%"}
      sx={{ padding: "1rem 2%", display: "flex", flexWrap: "wrap" }}
    >
      <Box
        sx={{
          width: { xs: "98%", sm: "98%", md: "50%", lg: "50%" },
        }}
      >
        <img
          loading="eager"
          alt="voice Recorder"
          src="../assets/group-3457.svg"
          style={{
            width: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "98%", sm: "98%", md: "50%", lg: "50%" },
          padding:{xs:"0.5rem 5%"}
        }}
      >
        <Typography
          sx={{
            textAlign: { xs: "center", sm: "start", lg: "start" },
            lineHeight: 1.2,
            fontWeight: 800,
            fontSize: { xs: "1.687rem", lg: "2.687rem" },
            // fontSize:{xs:"24px",sm:"2.687rem",md:"2.687rem",lg:"2.687rem"},
            background:
              "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontFamily: "inherit",
            // width:{xs:"90%",sm:"31.75rem",md:"31.75rem",lg:"31.75rem"}
          }}
        >
          Voice Recorder: Audio  Recording App
        </Typography>
        <Typography
          sx={{
            textAlign: { xs: "center", sm: "start", lg: "start" },
            fontFamily: "inherit",
            fontSize: "1.125rem",
            lineHeight: "1.406rem",
            wordWrap: "break-word",
            mt: "1rem",
            color: "gray",
          }}
        >
          Use our easy and quick voice recorder app as
        </Typography>
        <Box>
          {data.map((item, index) => (
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
              }}
            >
              <ListItem
                sx={{
                  display: "list-item",
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {item.heading}:
                </span>
                {item.content}
              </ListItem>
            </List>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default VoiceRecorderComponents;
