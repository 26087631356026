import React, { useState } from "react";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
// import required modules
// import required modules
import { EffectCards, Autoplay } from "swiper/modules";
import { Box, styled, useTheme, Stack } from "@mui/material";
// import Card from "../StyledComponents/Card";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
// import { CardActionArea } from "@mui/material";
import { motion } from "framer-motion";

const CardSwiper = () => {
  const theme = useTheme();
  const data = [
    {
      heading: `Video to Audio Conversion`,
      img: `../assets/myvideo.svg`,
      content: `Transform musicvideos, interviews, or podcasts into audio-only files (mp3) with the built-invideo converter. Enjoy the flexibility of listening to or using the audio inany way you desire.`,
    },
    {
      heading: `Fit to Email`,
      img: `../assets/largesize.svg`,
      content: `Shrink your videos or reduce video file size seamlessly with our compress videotool's exclusive "fit to Email" Feature.`,
    },
    // {
    //   heading: `Saving Storage Space`,
    //   img: `../assets/removeslected.svg`,
    //   content: `Compressing videos before storing them on your device or cloud storage services conserves storage space, allowing you to keep more videos without running out of storage capacity.`,
    // },
    {
      heading: `Trim or Cut Feature`,
      img: `../assets/trime.svg`,
      content: `Quickly edit and trim your videos with the in-built video trimmer. Remove unwanted
      parts of your videos in seconds,thanks to an intuitive user interface that allows you to select,replay,and trim each video part to milliseconds.`,
    },
    {
      heading: `Fast-Forward videoEffects`,
      img: `../assets/fast.svg`,
      content: `Add a dynamic touch to your videos by utilizing the fast-forward video feature.Speed up your videos and create impressive effects effortlessly.`,
    },
    {
      heading: `Quick and Easy Share`,
      img: `../assets/complete.svg`,
      content: `After easily compressing your videos, Share them effortlessly on platforms like WhatsApp, Instagram, Facebook, TikTok, and
      more with just a tap.Our user-friendly video compression tool ensures quick and effient file size reduction,enabling swift uploads and smoother sharing experiences.`,
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "1rem 1%",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {/* content */}
      <Box
        sx={{
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          width: { xs: "94%", sm: "60%", md: "70%", lg: "75%" },
        }}
      >
        <Stack
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            sx={{
              textAlign: { xs: "center", sm: "start", lg: "start" },
              lineHeight: 1.2,
              fontWeight: 800,
              fontSize: { xs: "1.687rem", lg: "2.687rem" },
              // fontSize:{xs:"24px",sm:"2.687rem",md:"2.687rem",lg:"2.687rem"},
              background:
                "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontFamily: "inherit",
              // width:{xs:"90%",sm:"31.75rem",md:"31.75rem",lg:"31.75rem"}
            }}
          >
            The All-in-One VideoCompressor app: Compress, Trim, Convert, and
            Share Your Videos on the Go{" "}
          </Typography>
          <Typography
            sx={{
              textAlign: { xs: "center", sm: "start", lg: "start" },
              fontFamily: "inherit",
              fontSize: "1.125rem",
              lineHeight: "1.406rem",
              wordWrap: "break-word",
              mt: "1rem",
            }}
          >
            More Than Just Compression: Transform Your Videos
          </Typography>
          <Typography
            sx={{
              textAlign: { xs: "center", sm: "start", lg: "start" },
              //   color: "red",
              fontWeight: 800,
              fontSize: "2.687rem",
              lineHeight: 1.2,
              background:
                "linear-gradient(to bottom, #4f4dd9 0%, #2b60aa 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
              mt: "2rem",
            }}
            variant={theme.typography.h2}
          >
            {`${activeIndex + 1}`}
            <span
              style={{
                color: "black",
                fontSize:"1.678rem"
              }}
            >
              {`.${data[activeIndex].heading}`}
            </span>
          </Typography>

          <Typography
            sx={{
              textAlign: { xs: "center", sm: "start", lg: "start" },
              color: "grey",
              mt: 1.2,
              ml:1
            }}
            variant={theme.typography.h2}
          >
            {data[activeIndex].content}
          </Typography>
        </Stack>
      </Box>
      {/* end content */}
      <Box
        sx={{
          width: { xs: "94%", sm: "40%", md: "30%", lg: "25%" },
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-end", lg: "flex-end" },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(../assets/Apple-iPhone-15-Frame-PNG.png)`, // Set background image
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "240px",
            height: "420px",
            // backgroundColor:"black"
          }}
        >
          <Box
            sx={{
              ".swiper": {
                width: "174px",
                height: "100%",
              },
              ".swiper-slide": {
                position: "relative",
                // borderRadius: "15px",
                //   backgroundImage: `url(../assets/Apple-iPhone-15-Frame-PNG.png)`, // Set background image
                //   backgroundSize: "cover",
                //   backgroundRepeat: "no-repeat",
                //   backgroundPosition: "center",
                // border:"1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                //   fontSize: 22,
                //   fontWeight: "bold",
                //   color: "#fff",
              },
            }}
          >
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[Autoplay]}
              autoplay={{ delay: 3000 }}
              loop={true}
              onSlideChange={(swiper) => handleSlideChange(swiper)}
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={item.img}
                    alt="akash"
                    style={{
                      width: "174px",
                      height: "400px",
                      objectFit: "fill",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardSwiper;

// new
// import React, { useState } from "react";
// import "swiper/css/bundle";
// import { Swiper, SwiperSlide } from "swiper/react";
// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/effect-cards";
// // Import required modules
// import { Autoplay } from "swiper/modules";
// import { Box, Typography, Stack, useTheme } from "@mui/material";

// const CardSwiper = () => {
//   const theme = useTheme();
//   const data = [
//     {
//       heading: `Reducing File Size`,
//       img: `../assets/myvideo.svg`,
//       content: `Compressing videos allows for easier sharing via email, messaging apps, or social media platforms, especially when dealing with large files that may exceed file size limits.`,
//     },
//     {
//       heading: `Optimizing for Uploads`,
//       img: `../assets/largesize.svg`,
//       content: `Compressing videos before uploading them to websites, blogs, or online platforms helps to reduce loading times and improve user experience, particularly on devices with slower internet connections.`,
//     },
//     {
//       heading: `Saving Storage Space`,
//       img: `../assets/removeslected.svg`,
//       content: `Compressing videos before storing them on your device or cloud storage services conserves storage space, allowing you to keep more videos without running out of storage capacity.`,
//     },
//     {
//       heading: `Enhancing Playback`,
//       img: `../assets/trime.svg`,
//       content: `Compressing videos for playback on mobile devices reduces buffering and ensures smoother playback, making it easier to watch videos on smartphones and tablets, even with limited network bandwidth.`,
//     },
//     {
//       heading: `Maintaining Quality`,
//       img: `../assets/fast.svg`,
//       content: `Compressing videos while preserving visual quality is ideal for archiving purposes, enabling you to store videos for long-term preservation without sacrificing too much in terms of video quality.`,
//     },
//     {
//       heading: `Akash`,
//       img: `../assets/complete.svg`,
//       content: `Compressing videos while preserving visual quality is ideal for archiving purposes, enabling you to store videos for long-term preservation without sacrificing too much in terms of video quality.`,
//     },
//   ];
//   const [activeIndex, setActiveIndex] = useState(0);

//   const handleSlideChange = (swiper) => {
//     setActiveIndex(swiper.realIndex);
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         padding: "2rem 1%",
//         width: "100%",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <Box sx={{ position: "relative", width: "240px", height: "420px" }}>
//         <img
//           src="../assets/Apple-iPhone-15-Frame-PNG.png"
//           alt="iPhone Frame"
//           style={{
//             width: "100%",
//             height: "100%",
//             objectFit: "contain",
//             position: "absolute",
//             top: 0,
//             left: 0,
//           }}
//         />
//         <Box
//           sx={{
//             overflow: "hidden",
//             position: "absolute",
//             top: "60%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "174px",
//             height: "400px",
//           }}
//         >
//           <Swiper
//             effect={"cards"}
//             grabCursor={true}
//             modules={[Autoplay]}
//             autoplay={{ delay: 3000 }}
//             loop={true}
//             onSlideChange={(swiper) => handleSlideChange(swiper)}
//           >
//             {data.map((item, index) => (
//               <SwiperSlide key={index}>
//                 <img
//                   src={item.img}
//                   alt={item.heading}
//                   style={{ width: "100%", height: "100%", objectFit: "contain" }}
//                 />
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </Box>
//       </Box>
//       <Box sx={{ marginLeft: "1rem", width: "50%" }}>
//         <Stack>
//           <Typography
//             sx={{
//               textAlign: "center",
//               color: "red",
//               fontWeight: 800,
//               fontSize: "2.687rem",
//               lineHeight: 1.2,
//             }}
//             variant={theme.typography.h2}
//           >
//             {data[activeIndex].heading}
//           </Typography>
//           <Typography
//             sx={{
//               textAlign: "center",
//               color: "red",
//               mt: 1.2,
//             }}
//             variant={theme.typography.h2}
//           >
//             {data[activeIndex].content}
//           </Typography>
//         </Stack>
//       </Box>
//     </Box>
//   );
// };

// export default CardSwiper;
