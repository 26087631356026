import React, { useState } from "react";
import styles from "./FrameComponent.module.css";
import PlayButton from "../../pages/PlayButton"; // Import PlayButton component

const FrameComponent = ({ togglePlayButton }) => {
  const [isRecording, setIsRecording] = useState(false);

  const handleClick = () => {
    togglePlayButton(); // Call togglePlayButton function passed as a prop
    setIsRecording(true); // Set recording state to true
  };

  return (
    <div className={styles.videoMakerFlipParent}>
      <div className={styles.videoMakerFlip}>
        <div className={styles.voiceRecorderParent}>
          <h1 className={styles.voiceRecorder}>
            <span>
              <span>{`Voice `}</span>
              <span className={styles.recorder}>Recorder</span>
            </span>
          </h1>
          <div className={styles.welcomeToVoice}>
            Welcome to Voice Recorder, your go-to destination for easy and
            high-quality voice recordings
          </div>
        </div>
        <div className={styles.contactInfo}>
          <div className={styles.separator}>
            <img
              className={styles.separatorChild}
              loading="lazy"
              alt=""
              src="../images/record1.gif"
              onClick={handleClick} // Call handleClick function on click
              style={{
                width: "200px",
                height: "200px",
                backgroundColor: "#FFFFFF",
                border: "none",
              }}
            />
            <h2 className={styles.pressMicTo}>Press Mic To Record</h2>
          </div>
        </div>
      </div>
      <div className={styles.mPConverter}>
        <img
          className={styles.mPConverterChild}
          loading="lazy"
          alt=""
          src="../images/group-3107.svg"
        />
      </div>
      {/* Render PlayButton component conditionally based on recording state */}
      {isRecording && <PlayButton />}
    </div>
  );
};

export default FrameComponent;
