// import React, { useRef, useState } from "react";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import DropboxChooser from "react-dropbox-chooser";
// import { Typography, useTheme } from "@mui/material";

// const APP_KEY = "ocxmbrakzrc47np";

// function AddFileButton() {
//   const theme = useTheme();
//   const fileInputRef = useRef(null);
//   const [showUrlInput, setShowUrlInput] = useState(false);
//   const [urlInput, setUrlInput] = useState("");
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleFileFromDevice = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };

//   const handleFileSelectionFromDevice = (e) => {
//     const selectedFile = e.target.files[0];
//     alert(`Selected file from device: ${selectedFile.name}`);
//     // Handle the selected file as needed
//     // navigate("/uploadingPage", { state: { selectedFile } });
//     // handleFileUpload(selectedFile);
//     console.log(selectedFile);
//   };

//   const handleFileFromDropbox = (files) => {
//     const selectedFile = files[0];
//     console.log("Selected file from Dropbox:", selectedFile);
//     // Handle the selected file from Dropbox as needed
//     // handleFileUpload(selectedFile);
//   };

//   const handleFileFromGoogleDrive = () => {
//     alert("Placeholder for handling file from Google Drive");
//     // You need to implement Google Drive file selection here
//   };

//   const handleFileFromUrl = () => {
//     setShowUrlInput(true);
//   };

//   const handleUrlSubmit = () => {
//     setShowUrlInput(false);
//     // Handle URL submission
//   };

//   const handleSuccessFromDropbox = (files) => {
//     console.log(files);
//     // Add logic to handle the files from Dropbox
//     setAnchorEl(null); // Close the menu after selecting from Dropbox
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <>
//       <Button
//         variant="contained"
//         sx={{
//           background: "#640cc61a",
//           "&:hover": { background: "#640cc61a" },
//           px: "32px",
//           py: "16px",
//           borderRadius: "60px",
//           boxShadow: "none",
//         }}
//         onClick={handleMenuOpen}
//         endIcon={<ArrowDropDownIcon sx={{ color: "#000000" }} />}
//       >
//         <Typography
//           sx={{
//             color: "#000000",
//             fontWeight: "500",
//             fontSize: "14px",
//           }}
//           variant={theme.typography.h1}
//         >
//           + Add More File
//         </Typography>
//       </Button>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//         sx={{
//           ml: "15px",
//         }}
//       >
//         <MenuItem onClick={handleFileFromDevice}>From Device</MenuItem>
//         <MenuItem onClick={handleFileFromDropbox}>
//           <DropboxChooser
//             appKey={APP_KEY}
//             success={handleSuccessFromDropbox}
//             cancel={() => setAnchorEl(null)}
//           >
//             <span>From Dropbox</span>
//           </DropboxChooser>
//         </MenuItem>
//         <MenuItem onClick={handleFileFromGoogleDrive}>
//           From Google Drive
//         </MenuItem>
//         <MenuItem onClick={handleFileFromUrl}>From URL</MenuItem>
//       </Menu>
//       <input
//         type="file"
//         ref={fileInputRef}
//         style={{ display: "none" }}
//         accept="video/*"
//         onChange={handleFileSelectionFromDevice}
//       />
//       {showUrlInput && (
//         <div className="url-input-section">
//           <input
//             type="text"
//             placeholder="Enter URL"
//             value={urlInput}
//             onChange={(e) => setUrlInput(e.target.value)}
//           />
//           <button onClick={handleUrlSubmit}>Submit URL</button>
//         </div>
//       )}
//     </>
//   );
// }

// export default AddFileButton;

import React, { useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import AddMoreButton from "../StyledComponents/AddMoreButton";

const AddFileButton = ({ onFilesSelected }) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);

  const handleFileSelection = (e) => {
    const selectedFiles = e.target.files[0];
    if (onFilesSelected) {
      onFilesSelected(selectedFiles);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="video/*"
        onChange={handleFileSelection}
        // multiple // Allow multiple file selection
      />
      {/* <Typography
        component="div"
        onClick={handleClick}
        sx={{
          background: "#640cc61a",
          px: "32px",
          py: "16px",
          borderRadius: "60px",
          cursor: "pointer",
          display: "inline-block",
        }}
      >
        <Typography
          sx={{
            color: "#000000",
            fontWeight: "500",
            fontSize: "14px",
          }}
          variant={theme.typography.h1}
        >
          + Add More Files
        </Typography>
      </Typography> */}
      <Box onClick={handleClick}>
        <AddMoreButton />
      </Box>
    </>
  );
};

export default AddFileButton;
