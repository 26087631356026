import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import YouTube from "react-youtube";

function YoutubeVideoComponent() {
  const youtubeVideoId = "MdKDR5A8LXw";
  const videoOpts = {
    // height: '390',
    // width: '340',
    playerVars: {
      autoplay: 1,
    },
  };
  return (
    <Box
      width={"100%"}
      sx={{ display: "flex", flexWrap: "wrap", padding: "3rem 5% 0.5rem 5%" }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "94%", md: "50%", lg: "50%" },
          height: { xs: "390px", sm: "390px", md: "390px", lg: "390px" },
          margin: "0 auto",
        }}
      >
        <YouTube
          videoId={youtubeVideoId}
          opts={{ ...videoOpts, width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "98%", sm: "98%", md: "50%", lg: "50%" },
          padding: "0.5rem 0% 0.5rem 2%",
          display:"flex",
          justifyContent:"center",
          alignItems:"flex-start"
        }}
      >
        <Stack>
        <Typography
          sx={{
            textAlign: { xs: "center", sm: "start", lg: "start" },
            lineHeight: 1.2,
            fontWeight: 800,
            fontSize: { xs: "1.687rem", lg: "2rem" },
            // fontSize:{xs:"24px",sm:"2.687rem",md:"2.687rem",lg:"2.687rem"},
            background:
              "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontFamily: "inherit",
            // width:{xs:"90%",sm:"31.75rem",md:"31.75rem",lg:"31.75rem"}
          }}
        >
          Learn more about our video compressor app from this YouTube video.
        </Typography>
        <Typography
          sx={{
            textAlign: { xs: "center", sm: "start", lg: "start" },
            fontFamily: "inherit",
            fontSize: "1.125rem",
            lineHeight: "1.406rem",
            wordWrap: "break-word",
            mt: "1rem",
            color: "gray",
          }}
        >
          Try Online or Download Now our video compressor tool, elevate your
          digital storytelling with our efficient video compression solution.
          Empower your creativity and make every video easy to store or share
          for an easy and remarkable experience!
        </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

export default YoutubeVideoComponent;
