
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  backgroundColor: 'transparent', // Set background to transparent
  overflow: 'hidden', // Hide the overflow
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(270deg, #6316E6 0%, #C353F7 100%)'
  },
}));

export default function Loader() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="indeterminate" sx={{ position: "relative", top: "7px" }} />
    </Box>
  );
 }