import Header from "./Headerv";
import MergeAudio from "./MergeAudio";
import styles from "./ResizeVideoButton1.module.css";

const ResizeVideoButton1 = () => {
  return (
    <div className={styles.resizeVideoButton}>
      <div className={styles.mPConverterLink}>
        <img
          className={styles.mPConverterLinkChild}
          loading="lazy"
          alt=""
          src="../images/group-3121@2x.png"
        />
        <Header playback="Playback" />
        <img
          className={styles.mPConverterLinkItem}
          loading="lazy"
          alt=""
          src="../images/group-3127@2x.png"
        />
      </div>
      <div className={styles.rotateTool}>
        <div className={styles.cropTool}>
          <MergeAudio recording="Recording" />
          <div className={styles.videoCompressor}>
            <div className={styles.videoCompressorChild} />
            <div className={styles.qRCreator}>
              <div className={styles.wrapperContactInfo}>
                <img
                  className={styles.contactInfoIcon}
                  alt=""
                  src="../images/contact-info@2x.png"
                />
              </div>
              <img
                className={styles.maskGroupIcon}
                loading="lazy"
                alt=""
                src="../images/mask-group@2x.png"
              />
            </div>
          </div>
          <MergeAudio
            recording="File Management"
            propPadding="unset"
            propWidth="unset"
            propPadding1="0rem 0rem 0rem var(--padding-5xs)"
          />
        </div>
      </div>
    </div>
  );
};

export default ResizeVideoButton1;
