import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: "linear-gradient(270deg, #6316E6 0%, #C353F7 100%)",
    // background: "black",
  },
}));
const PercentageText = styled(Typography)(({ progress }) => {
  // let leftValue = `calc(${progress}% - 10px)`; // Default value

  // if (bar === "Uploading") {
  //   leftValue = `calc(${progress}% - 150px)`; // Adjust the value for Uploading
  // } else if (bar === "Compressing") {
  //   leftValue = `calc(${progress}% - 150px)`; // Adjust the value for Compressing
  // }

  return {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    // textAlign: "center",
    padding: "1rem 2%",
    width: "100%",
    // left: leftValue,
    left:`calc(${progress}% - 28px)`,
    color: "white",
    fontSize: "10px",
    pointerEvents: "none",
    borderRadius:"10px"
  };
});

export default function CompressingProgressBar({ progress }) {
  return (
    <Box sx={{ flexGrow: 1, position: 'relative',top:"7px" }}>
      <BorderLinearProgress
        variant="determinate"
        value={progress}
      />
      <PercentageText progress={progress}>
        {`${Math.round(progress)}%`}
      </PercentageText>
    </Box>
  );
}
