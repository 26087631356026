import React,{useState} from "react";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FlexCenter from "./StyledComponents/FlexCenter";
import DropdownComponents from "./StyledComponents/DropdownBtn";
import { motion } from "framer-motion";
import useInterval from "./StyledComponents/useInterval";

function ChoseFile() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(min-width:1000px)");
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const backgrounds = [
    "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
    "linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 255, 0, 1))",
    "linear-gradient(to right, rgba(0, 128, 0, 1), rgba(0, 0, 255, 1))",
    "linear-gradient(to right,  rgba(34,193,195,1), rgba(253,187,45,1)",
  ];

  // Change background every 3 seconds
  useInterval(() => {
    setBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
  }, 3000);

  return (
    <Box>
      {/* <FlexCenter> */}
      <Stack direction="column">
        {/* <Box> */}
        {/* <Stack direction="column"> */}
        {/* Apply linear gradient to "Video" text */}
        <Box
          sx={{
            display: !isSmallScreen ? "flex" : "",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            component={motion.h2}
            sx={{
              // fontSize: !isSmallScreen ? "46px" : "76px",
              fontSize: "calc(1.825rem + 2.1vw)",
              fontWeight: 800,
              lineHeight: 1,
              // background:
              //   "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
              // background: backgrounds[backgroundIndex], // Dynamically change background
              // WebkitBackgroundClip: "text",
              // color: "transparent",
              // position: "relative",
              // top: !isSmallScreen ? "26px" : "45px",
              // left:!isSmallScreen ?"90px":""
            }}
            animate={{
              background: backgrounds[backgroundIndex],
              color: "#1C1C1C",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }} // Animate background and text color changes
            transition={{
              duration: 0.5, // Duration of the transition
            }}
            variant={theme.typography.fontFamily}
          >
            Video {isSmallScreen && <br />}
            {/* <span
              style={{
                color: "#1C1C1C",
                textAlign: !isSmallScreen ? "center" : "",
              }}
            > */}
              Compressor
            {/* </span> */}
          </Typography>
        </Box>
        {/* Apply black color to "Compressor" text */}
        {/* <Typography
              sx={{
                display: { xs: "block" },
                fontSize: !isSmallScreen ? "46px" : "76px",
                fontWeight: 600,
                color: "#1C1C1C",
                textAlign:!isSmallScreen ?"center":""
              }}
              variant={theme.typography.h1}
            >
              Compressor
            </Typography> */}
        {/* </Stack> */}
        {/* </Box> */}
        <Box
          width={!isSmallScreen ? "100%" : "520px"}
          sx={{
            wordWrap: "break-word",
            padding: !isSmallScreen ? "0rem 3%" : "",
            display: !isSmallScreen ? "flex" : "",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: !isSmallScreen ? "16px" : "20px",
              fontWeight: "400",
              textAlign: "center",
            }}
            variant={theme.typography.fontFamily}
          >
            <span style={{ color: "#727CF5" }}>
              Quickly reduce video size with our Video compressor tool
            </span>{" "}
            <span style={{ color: "black" }}>powered by 4K Compressor </span>
          </Typography>
        </Box>
        {!isSmallScreen ? (
          <FlexCenter>
            <DropdownComponents />
          </FlexCenter>
        ) : (
          <Box sx={{ mt: 3 }}>
            <DropdownComponents />
          </Box>
        )}
      </Stack>
      {/* </FlexCenter> */}
    </Box>
  );
}

export default ChoseFile;
