import React, { useRef } from "react";
import { Box, Typography, Stack, useMediaQuery, useTheme } from "@mui/material";
import FlexCenter from "./StyledComponents/FlexCenter";
import FlexBetween from "./StyledComponents/FlexBetween";
import AppStore from "./StyledComponents/AppStore";
import PricingPlan from "./pricingPlan";
import TryFree from "./tryFree";
import CardSlider from "./StyledComponents/CardSlider";
// import Card from "./StyledComponents/Card";
import { motion, useScroll } from "framer-motion";

function Blog() {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.23 1"],
  });
  return (
    <Box
      mt={2}
      boxSizing="border-box"
      sx={{
        background:
          "linear-gradient(180deg, rgba(114, 124, 245, 0.02) 0%, rgba(114, 124, 245, 0.25) 100%)",
      }}
    >
      <Box padding="0rem 4%">
        <Box
          ref={ref}
          component={motion.div}
          boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.20)"
          borderRadius="10px"
          pt="1%"
          pb={isNonMobile ? "0%" : "10%"}
        >
          <FlexCenter>
            <Typography
              sx={{
                ml: isNonMobile ? "0px" : "20px",
                color: "black",
                fontSize: isNonMobile ? "36px" : "28px",
                fontWeight: "500",
                wordWrap: "break-word",
                // width:"60%"
              }}
              variant={theme.typography.fontFamily}
            >
              How to <span style={{ color: "#727CF5" }}>compress a video</span>{" "}
              ?
            </Typography>
          </FlexCenter>
          <FlexCenter sx={{ mt: 1 }}>
            <Typography
              sx={{
                ml: isNonMobile ? "0px" : "20px",
                color: "#18191B",
                fontSize: "20px",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
              variant={theme.typography.fontFamily}
            >
              Easily Compress videos with three simple steps:
            </Typography>
          </FlexCenter>
          <FlexCenter
            width="100%"
            padding={isNonMobile ? "2rem 4%" : "0rem 2%"}
          >
            <FlexBetween
              gap={isNonMobile ? 4 : 2}
              display={{ xs: "block", md: "flex" }}
            >
              {/* <Box
                width={isNonMobile ?"100%":"90%"}
                sx={{
                  ml:isNonMobile ?"0px":"15px",
                  border: "none 2px solid",
                  borderRadius: "10px",
                  boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.10)",
                  p: 4,
                  bgcolor: "#FFFFFF",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                    boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  },
                }}
              >
                <img
                  src="../assets/1.svg"
                  alt=""
                  style={{
                    color: "white",
                    height: "30px",
                    width: "37px",
                    objectFit: "cover",
                  }}
                />
                <Box sx={{ justifyContent: "flex-start", mt: 1 }}>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        wordWrap: "break-word",
                        color: "#727CF5",
                      }}
                      variant={theme.typography.fontFamily}
                    >
                      Upload <span style={{ color: "black" }}>a video</span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: "16px", color: "rgba(0,0,0,0.50)" }}
                      variant={theme.typography.fontFamily}
                    >
                      Choose a file up to 1GB for free from any of your devices:
                      Android, iPhone, Mac, Windows. You can also select it from
                      your Google Drive or Dropbox account.
                    </Typography>
                  </Stack>
                </Box>
              </Box> */}
              <Box
                component={motion.div}
                style={{
                  opacity:scrollYProgress,
                  scale:scrollYProgress
                }}
                // minWidth="300px"
                width={"100%"}
                // height="260px"
                sx={{
                  border: "none 2px solid",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  p: 4,
                  bgcolor: "#FFFFFF",
                  // transition: "transform 0.3s ease-in-out",
                  // "&:hover": {
                  //   transform: isNonMobile ? "scale(1.1)" : "",
                  //   boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  // },
                }}
              >
                <img
                  src="../assets/1.svg"
                  alt=""
                  style={{
                    color: "white",
                    height: "30px",
                    width: "37px",
                    objectFit: "cover",
                  }}
                />
                <Box sx={{ justifyContent: "flex-start", mt: 1 }}>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        wordWrap: "break-word",
                        color: "#727CF5",
                      }}
                      variant={theme.typography.fontFamily}
                    >
                      Upload <span style={{ color: "black" }}>a video</span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: "16px", color: "rgba(0,0,0,0.50)" }}
                      variant={theme.typography.fontFamily}
                    >
                      Choose a file up to 1GB for free from any of your devices:
                      Android, iPhone, Mac, Windows. You can also select it from
                      your Google Drive or Dropbox account.
                    </Typography>
                  </Stack>
                </Box>
              </Box>
              {/* <Box
                 width={isNonMobile ?"100%":"90%"}
                sx={{
                  ml:isNonMobile ?"0px":"15px",
                  border: "none 2px solid",
                  borderRadius: "10px",
                  boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.10)",
                  p: 4,
                  bgcolor: "#FFFFFF",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                    boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  },
                }}
                mt={isNonMobile ? "0px" : "24px"}
              >
                <img
                  src="../assets/_icons.svg"
                  alt=""
                  style={{
                    color: "white",
                    height: "30px",
                    width: "37px",
                    objectFit: "cover",
                  }}
                />
                <Box sx={{ justifyContent: "flex-start", mt: 1 }}>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        wordWrap: "break-word",
                        color: "#727CF5",
                      }}
                      variant={theme.typography.fontFamily}
                    >
                      Compress <span style={{ color: "black" }}>the file</span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: "16px", color: "rgba(0,0,0,0.50)" }}
                      variant={theme.typography.fontFamily}
                    >
                      Watch the preview to make sure everything is okay. If it's
                      so, download the video back to your device, otherwise, go
                      back to editing.
                    </Typography>
                  </Stack>
                </Box>
              </Box> */}
              <Box
                component={motion.div}
                style={{
                  opacity:scrollYProgress,
                  scale:scrollYProgress
                }}
                // minWidth="300px"
                width={"100%"}
                // height="260px"
                sx={{
                  border: "none 2px solid",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  p: 4,
                  bgcolor: "#FFFFFF",
                  // transition: "transform 0.3s ease-in-out",
                  // "&:hover": {
                  //   transform: isNonMobile ? "scale(1.1)" : "",
                  //   boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  // },
                }}
                mt={isNonMobile ? "0px" : "24px"}
              >
                <img
                  src="../assets/_icons.svg"
                  alt=""
                  style={{
                    color: "white",
                    height: "30px",
                    width: "37px",
                    objectFit: "cover",
                  }}
                />
                <Box sx={{ justifyContent: "flex-start", mt: 1 }}>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        wordWrap: "break-word",
                        color: "#727CF5",
                      }}
                      variant={theme.typography.fontFamily}
                    >
                      Compress <span style={{ color: "black" }}>the file</span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: "16px", color: "rgba(0,0,0,0.50)" }}
                      variant={theme.typography.fontFamily}
                    >
                      Watch the preview to make sure everything is okay. If it's
                      so, download the video back to your device, otherwise, go
                      back to editing.
                    </Typography>
                  </Stack>
                </Box>
              </Box>

              {/* <Box
                 width={isNonMobile ?"100%":"90%"}
                sx={{
                  ml:isNonMobile ?"0px":"15px",
                  border: "none 2px solid",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.10)",
                  p: 4,
                  bgcolor: "#FFFFFF",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                    boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  },
                }}
                mt={isNonMobile ? "0px" : "24px"}
              >
                <img
                  src="../assets/download.svg"
                  alt=""
                  style={{
                    color: "white",
                    height: "30px",
                    width: "37px",
                    objectFit: "cover",
                  }}
                />
                <Box sx={{ justifyContent: "flex-start", mt: 1 }}>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        wordWrap: "break-word",
                        color: "#727CF5",
                      }}
                      variant={theme.typography.fontFamily}
                    >
                      Download{" "}
                      <span style={{ color: "black" }}>the result</span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: "16px", color: "rgba(0,0,0,0.50)" }}
                      variant={theme.typography.fontFamily}
                    >
                      Wait a few moments while the tool is setting up the
                      necessary parameters for your video. You can learn more
                      about the tool in the how to compress a video guide.
                    </Typography>
                  </Stack>
                </Box> */}
              {/* </Box> */}
              <Box
               component={motion.div}
               style={{
                opacity:scrollYProgress,
                scale:scrollYProgress
              }}
                // minWidth="300px"
                width={"100%"}
                // height="260px"
                sx={{
                  border: "none 2px solid",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  p: 4,
                  bgcolor: "#FFFFFF",
                  // transition: "transform 0.3s ease-in-out",
                  // "&:hover": {
                  //   transform: isNonMobile ? "scale(1.1)" : "",
                  //   boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
                  // },
                }}
                mt={isNonMobile ? "0px" : "24px"}
              >
                <img
                  src="../assets/download.svg"
                  alt=""
                  style={{
                    color: "white",
                    height: "30px",
                    width: "37px",
                    objectFit: "cover",
                  }}
                />
                <Box sx={{ justifyContent: "flex-start", mt: 1 }}>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        wordWrap: "break-word",
                        color: "#727CF5",
                      }}
                      variant={theme.typography.fontFamily}
                    >
                      Download{" "}
                      <span style={{ color: "black" }}>the result</span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: "16px", color: "rgba(0,0,0,0.50)" }}
                      variant={theme.typography.fontFamily}
                    >
                      Wait a few moments while the tool is setting up the
                      necessary parameters for your video. You can learn more
                      about the tool in the how to compress a video guide.
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </FlexBetween>
          </FlexCenter>
        </Box>
      </Box>
      <Box padding={isNonMobile ? "2% 6%" : "0px 4%"} mt={isNonMobile ? 0 : 5}>
        <FlexCenter>
          <Typography
            sx={{
              color: "black",
              fontSize: isNonMobile ? "36px" : "28px",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
            variant={theme.typography.fontFamily}
          >
            Why <span style={{ color: "#727CF5" }}>Choose Us</span> ?
          </Typography>
        </FlexCenter>
        <Box>
          <CardSlider />
        </Box>
      </Box>
      {/* <Box padding="1rem 3%">
        <FlexCenter>
          <Typography
            sx={{
              color: "black",
              fontSize: "24px",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            Get it on Mobile
          </Typography>
        </FlexCenter>
        <FlexCenter>
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Compress videos directly on your mobile device using our{" "}
            <span style={{ color: "#727CF5" }}>Android Video Compressor</span>{" "}
            or
            <span style={{ color: "#727CF5" }}> iOS Video Compressor</span>
          </Typography>
        </FlexCenter>
        <Box mt={4}>
          <FlexCenter>
            <FlexBetween gap={2} display={{ xs: "block", md: "flex" }}>
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                mt={isNonMobile ? "0px" : "10px"}
              >
                <img
                  src="../assets/apple.svg"
                  alt=""
                  style={{
                    //   color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "fill",
                  }}
                />
              </Box>
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                mt={isNonMobile ? "0px" : "10px"}
              >
                <img
                  src="../assets/google.svg"
                  alt=""
                  style={{
                    //   color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "fill",
                  }}
                />
              </Box>
            </FlexBetween>
          </FlexCenter>
        </Box> */}
      {/* </Box> */}
      <Box padding="1rem 3%" mt="2%">
        <AppStore />
      </Box>
      {/* <Box padding="0.1rem 3%">
        <PricingPlan />
      </Box> */}
      {/* <Box padding={isNonMobile ? "2rem 10%" : "1rem 5%"}>
        <TryFree />
      </Box> */}
    </Box>
  );
}

export default Blog;
