import React, { useState } from "react";
import { Box, Typography, Button, useMediaQuery, Stack } from "@mui/material";
import FlexCenter from "../StyledComponents/FlexCenter";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/system";
import FlexBetween from "../StyledComponents/FlexBetween";
import { useNavigate } from "react-router-dom";
import ShareIcon from "@mui/icons-material/Share";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledButton = styled(Button)({
  backgroundColor: "#727cf5",
  borderRadius: "10px",
  height: "65px",
  width: "100%",
});

function DoneComponents({
  filename,
  originalfilesize,
  compressedfilesize,
  finalUrl,
}) {
  const navigate = useNavigate();
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");
  const [open, setOpen] = useState(false);

  // const handleDownloadClick = async () => {

  //   // Trigger download
  //   const link = document.createElement("a");
  //   link.href = finalUrl;
  //   link.download = "compressed-file.mp4";
  //   link.click();
  // };
  const handleDownloadClick = async () => {
    try {
      // Fetch the video file
      const response = await fetch(finalUrl);

      // Get the filename from the response headers or set a default name
      const fileNames = `${filename}`;

      // Create a Blob from the response
      const blob = await response.blob();

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileNames;
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const navigateToVideo = () => {
    navigate("/videocompressor");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box padding={isNonMobileScreen ? "0rem 3%" : "0rem 0%"}>
      {isNonMobileScreen && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box>
            <img
              src="../assets/done.svg"
              alt=""
              style={{
                color: "white",
                height: "30px",
                width: "30px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box sx={{ position: "relative", width: "100%" }}>
            <Typography
              sx={{
                color: "#FF585C",
                fontSize: "16px",
                fontWeight: "400",
                position: "absolute",
                top: "3px",
              }}
            >
              Compressed by 50%
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 1 }}>
        <Typography
          sx={{
            color: "#00000080",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          {filename}
        </Typography>
      </Box>
      <Box
        sx={{
          display: isNonMobileScreen ? "" : "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: isNonMobileScreen ? "380px" : "247px",
            mt: "20px",
          }}
        >
          <FlexCenter
            sx={{
              width: "100%",
              backgroundColor: "#F8F8F8",
              padding: "1rem",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          >
            <Stack sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  color: "#00000080",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Original
              </Typography>
              <Typography
                sx={{
                  color: "#00000080",
                  fontSize: "16px",
                  fontWeight: "600",
                  mt: 1,
                }}
              >
                {originalfilesize}MB
              </Typography>
              <Typography
                sx={{
                  color: "#00000080",
                  fontSize: "16px",
                  fontWeight: "400",
                  mt: 1,
                }}
              >
                1920x1080
              </Typography>
            </Stack>
          </FlexCenter>
          <FlexCenter
            sx={{
              width: "100%",
              background:
                "linear-gradient(90deg, rgba(251,92,106,1) 0%, rgba(194,55,137,1) 44%, rgba(190,96,252,1) 100%)",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              padding: "1rem",
            }}
          >
            <Stack sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Compressed
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "600",
                  mt: 1,
                }}
              >
                {compressedfilesize}MB
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "400",
                  mt: 1,
                }}
              >
                1920x1080
              </Typography>
            </Stack>
          </FlexCenter>
        </Box>
      </Box>
      <FlexCenter>
        <FlexBetween pt={"1rem"}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              padding={"1rem"}
              sx={{
                borderRadius: "50%",
                background:
                  "linear-gradient(90deg, rgba(77,240,110,1) 17%, rgba(7,174,247,1) 100%)",
                m: isNonMobileScreen ? "0rem -15px" : "0rem 0px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                transition: "box-shadow 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.25)",
                  cursor: "pointer",
                },
              }}
              onClick={handleClickOpen}
            >
              <ShareIcon sx={{ color: "white" }} />
            </Box>
            {/* <Typography
              sx={{
                color: "#00000080",
                fontSize: "16px",
                fontWeight: "400",
                mt:"10px"
              }}
            >
              Share
            </Typography> */}
          </Stack>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              padding={"1rem"}
              sx={{
                borderRadius: "50%",
                background:
                  "linear-gradient(90deg, rgba(240,227,77,1) 10%, rgba(247,95,7,1) 100%)",
                m: isNonMobileScreen ? "0rem 30px" : "0rem 15px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                transition: "box-shadow 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.25)",
                  cursor: "pointer",
                },
              }}
              onClick={handleDownloadClick}
            >
              <DownloadIcon sx={{ color: "white" }} />
            </Box>
            {/* <Typography
              sx={{
                color: "#00000080",
                fontSize: "16px",
                fontWeight: "400",
                mt:"10px"
              }}
            >
              Download
            </Typography> */}
          </Stack>
        </FlexBetween>
      </FlexCenter>
      {/* akash */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          zIndex: "999999",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Share
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FacebookShareButton url={finalUrl}>
            <FacebookIcon round={true} />
          </FacebookShareButton>

          <WhatsappShareButton url={finalUrl}>
            <WhatsappIcon round={true} />
          </WhatsappShareButton>
        </DialogContent>
        {/* <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Save changes
                </Button>
              </DialogActions> */}
      </BootstrapDialog>
      {/* end akash */}
    </Box>
  );
}

export default DoneComponents;
// import React, { useState } from "react";
// import { Box, Typography, Button, useMediaQuery, Stack } from "@mui/material";
// import FlexCenter from "../StyledComponents/FlexCenter";
// import DownloadIcon from "@mui/icons-material/Download";
// import { styled } from "@mui/system";
// import FlexBetween from "../StyledComponents/FlexBetween";
// import { useNavigate } from "react-router-dom";
// import ShareIcon from "@mui/icons-material/Share";

// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

// const StyledButton = styled(Button)({
//   backgroundColor: "#727cf5",
//   borderRadius: "10px",
//   height: "65px",
//   width: "100%",
// });

// function DoneComponents({
//   filename,
//   originalfilesize,
//   compressedfilesize,
//   finalUrl,
// }) {
//   const navigate = useNavigate();
//   const isNonMobileScreen = useMediaQuery("(min-width:900px)");
//   const [open, setOpen] = useState(false);

//   const handleDownloadClick = async () => {
//     try {
//       // Your download logic
//     } catch (error) {
//       console.error("Error downloading the file:", error);
//     }
//   };

//   const navigateToVideo = () => {
//     navigate("/videocompressor");
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const shareToFacebook = () => {
//     // Your logic to share the video to Facebook
//     // This typically involves server-side integration and obtaining an access token
//   };

//   const shareToWhatsApp = () => {
//     const pageUrl = window.location.href;
//     // Create a whatsapp:// link to share the video
//     const message = `Check out this video: ${finalUrl}\n\nVisit our page: ${pageUrl}`;

//     // Encode the message
//     const encodedMessage = encodeURIComponent(message);
//     const whatsappLink = `whatsapp://send?text=${encodedMessage}`;
//     window.open(whatsappLink);
//   };

//   return (
//     <Box padding={isNonMobileScreen ? "0rem 3%" : "0rem 0%"}>
//       <Box>
//         <Typography
//           sx={{
//             color: "#00000080",
//             fontSize: "16px",
//             fontWeight: "400",
//           }}
//         >
//           {filename}
//         </Typography>
//       </Box>
//       <Box
//         sx={{
//           display: isNonMobileScreen ? "" : "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         {/* Original and Compressed boxes */}
//       </Box>
//       <FlexCenter>
//         <FlexBetween padding={"1rem"}>
//           <FlexCenter
//             padding={"1rem"}
//             sx={{
//               borderRadius: "50%",
//               background:
//                 "linear-gradient(90deg, rgba(77,240,110,1) 17%, rgba(7,174,247,1) 100%)",
//               m: "0rem 10px",
//               boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
//               transition: "box-shadow 0.3s ease-in-out",
//               "&:hover": {
//                 boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.25)",
//                 cursor: "pointer",
//               },
//             }}
//             onClick={handleClickOpen}
//           >
//             <ShareIcon sx={{ color: "white" }} />
//           </FlexCenter>
//           <FlexCenter
//             padding={"1rem"}
//             sx={{
//               borderRadius: "50%",
//               background:
//                 "linear-gradient(90deg, rgba(240,227,77,1) 10%, rgba(247,95,7,1) 100%)",
//               m: "0rem 10px",
//               boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
//               transition: "box-shadow 0.3s ease-in-out",
//               "&:hover": {
//                 boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.25)",
//                 cursor: "pointer",
//               },
//             }}
//             onClick={handleDownloadClick}
//           >
//             <DownloadIcon sx={{ color: "white" }} />
//           </FlexCenter>
//         </FlexBetween>
//       </FlexCenter>

//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby="customized-dialog-title"
//         open={open}
//         sx={{
//           zIndex: "999999",
//         }}
//       >
//         <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
//           Share
//         </DialogTitle>
//         <DialogContent dividers>
//           <Button variant="contained" onClick={shareToFacebook}>
//             Share on Facebook
//           </Button>
//           <Button variant="contained" onClick={shareToWhatsApp}>
//             Share on WhatsApp
//           </Button>
//         </DialogContent>
//         <DialogActions>
//           <Button autoFocus onClick={handleClose}>
//             Close
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </Box>
//   );
// }

// export default DoneComponents;
