import React from 'react';
import { Box } from '@mui/material';

const CustomLoader = () => {
  return (
    <Box className="loader"></Box>
  );
};
export default CustomLoader;



