import React, { useRef, useEffect, useState } from "react";
import styles from "./PlayButton.module.css";
import style from "./PlayButtonglobal.css";
import Editbutton from "./Editbutton";

const PlayButton = () => {
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyzerRef = useRef(null);
  const audioElementRef = useRef(null); // Ref for audio element
  const [isRecording, setIsRecording] = useState(true);
  const [showEditButton, setShowEditButton] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [frequencyData, setFrequencyData] = useState([]);
  const [blobAudio, setBlobAudio] = useState("");
  const [recordedAudio, setRecordedAudio] = useState(null); // Store recorded audio data
  const [recordDuration, setRecordDuration] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    analyzerRef.current = audioContextRef.current.createAnalyser();
    const analyzer = analyzerRef.current;
    const dataArray = new Uint8Array(analyzer.frequencyBinCount);

    const draw = () => {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      analyzer.getByteFrequencyData(dataArray);

      ctx.fillStyle = "rgb(255, 255, 255)";
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      const barWidth = (WIDTH / analyzer.frequencyBinCount) * 4;
      let barHeight;
      let x = 0;

      for (let i = 0; i < analyzer.frequencyBinCount; i++) {
        barHeight = dataArray[i] * 2;

        ctx.fillStyle = "rgb(" + (barHeight + 100) + ",50,50)";
        ctx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight);

        x += barWidth + 4;
      }

      requestAnimationFrame(draw);
    };

    draw();

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const source = audioContextRef.current.createMediaStreamSource(stream);
        console.log("source", source);
        source.connect(analyzer);

        const mediaRecorder = new MediaRecorder(stream);
        const recordedChunks = [];

        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            recordedChunks.push(e.data);
          }
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(recordedChunks, { type: "audio/wav" }); // Adjust the type as per your audio format
          const audioUrl = URL.createObjectURL(blob);
          console.log("Audio URL:", audioUrl);
          setBlobAudio(audioUrl);
          // Now you can use the audio URL as needed, such as playing it or storing it
        };

        mediaRecorder.start();
        setTimeout(() => {
          mediaRecorder.stop();
        }, 8000); // Stop recording after 5 seconds
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });

    return () => {
      audioContextRef.current.close();
    };
  }, []);

  useEffect(() => {
    let timerId;
    if (isRecording) {
      setStartTime(Date.now() - timeElapsed);
      timerId = setInterval(() => {
        setTimeElapsed(Date.now() - startTime);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [isRecording, startTime, timeElapsed]);

  const toggleRecording = () => {
    const audioContext = audioContextRef.current;
    if (isRecording) {
      audioContext.suspend();
    } else {
      audioContext.resume();
    }
    setIsRecording(!isRecording);
  };

  const handleStopClick = async () => {
    setShowEditButton(true);
    setIsRecording(false);
    setTimeElapsed(Date.now() - startTime);

    const audioContext = audioContextRef?.current;
    audioContext.suspend(); // Suspend the audio context

    const stream =
      audioContextRef.current.createMediaStreamDestination().stream;
    if (!stream) {
      console.error("Failed to capture audio stream.");
      return;
    }

    // Convert the audio stream to a Blob
    const recordedChunks = [];
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    };
    mediaRecorder.onstop = () => {
      const blob = new Blob(recordedChunks, { type: "audio/mp3" }); // Adjust the type as per your audio format
      setRecordedAudio(blob); // Save the recorded audio as Blob

      // Create a new audio element dynamically for playback
      const audioElement = new Audio();
      const audioUrl = URL.createObjectURL(blob);
      audioElement.src = audioUrl;
      audioElement.play();
    };

    mediaRecorder.start();
  };
  // const handleDeleteRecording = () => {
  //   setRecordedAudio(null); // Delete the recorded audio data
  //   setShowEditButton(false); // Hide the Editbutton component
  // };

  return (
    <div className={styles.voiceWebsite}>
      {!showEditButton ? (
        <section className={styles.emptyFrameParent}>
          <img
            className={styles.emptyFrameIcon}
            alt=""
            src="../images/empty-frame@2x.png"
          />
          <div className={styles.recordingText}>
            <h1 className={styles.recording}>
              {isRecording ? "Recording..." : "Recording Paused"}
            </h1>
          </div>
          <div className={styles.innerFrame}>
            <header className={styles.childFrame}>
              <div className={styles.childFrameChild} />
              <canvas ref={canvasRef} className={styles.childFrameItem} />
            </header>
            <div className={styles.placeholderText}>
              {formatTime(timeElapsed)}
            </div>
          </div>
          <div className={styles.vectorGroup}>
            <div className={styles.frameParent}>
              <img
                className={styles.frameChild}
                loading="lazy"
                alt=""
                src={
                  isRecording
                    ? "../images/pause1.svg"
                    : "../images/group-3113.svg"
                }
                onClick={toggleRecording}
              />
              <div className={styles.rectangleParent}>
                <div className={styles.frameItem} />
                <img
                  className={styles.childVectorIcon}
                  alt=""
                  src="../images/pause.svg"
                />
              </div>
            </div>
            <div className={styles.pauseFrame}>
              {isRecording ? (
                <div className={styles.pause} onClick={toggleRecording}>
                  Pause
                </div>
              ) : (
                <div className={styles.Play} onClick={toggleRecording}>
                  Play
                </div>
              )}

              <div className={styles.stop} onClick={handleStopClick}>
                <img
                  className={styles.frameChilds}
                  loading="lazy"
                  src="../images/stop.svg"
                  alt="Stop"
                />{" "}
                {/* <span className={styles.stopText}>Stop</span> */}
                <div className={styles.stopText} onClick={toggleRecording}>
                  Stop
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Editbutton
          blobAudio={blobAudio}
          recordedAudio={recordedAudio} // Pass the recorded audio data
        />
      )}
      
     </div>
  );
};

const formatTime = (milliseconds) => {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
  return (
    `${hours < 10 ? "0" : ""}${hours}:` +
    `${minutes < 10 ? "0" : ""}${minutes}:` +
    `${seconds < 10 ? "0" : ""}${seconds}`
  );
};

export default PlayButton;
//************************************** */
// import React, { useRef, useEffect, useState } from "react";
// import styles from "./PlayButton.module.css";
// import style from "./PlayButtonglobal.css";
// import Editbutton from "./Editbutton";

// const PlayButton = () => {
//   const canvasRef = useRef(null);
//   const audioContextRef = useRef(null);
//   const analyzerRef = useRef(null);
//   const audioElementRef = useRef(null); // Ref for audio element
//   const [isRecording, setIsRecording] = useState(true);
//   const [showEditButton, setShowEditButton] = useState(false);
//   const [startTime, setStartTime] = useState(0);
//   const [timeElapsed, setTimeElapsed] = useState(0);
//   const [frequencyData, setFrequencyData] = useState([]);
//   const [blobAudio, setBlobAudio] = useState("");
//   const [recordedAudio, setRecordedAudio] = useState(null); // Store recorded audio data
//   const [recordDuration, setRecordDuration] = useState(0);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     audioContextRef.current = new (window.AudioContext ||
//       window.webkitAudioContext)();
//     analyzerRef.current = audioContextRef.current.createAnalyser();
//     const analyzer = analyzerRef.current;
//     const dataArray = new Uint8Array(analyzer.frequencyBinCount);

//     const draw = () => {
//       const WIDTH = canvas.width;
//       const HEIGHT = canvas.height;

//       analyzer.getByteFrequencyData(dataArray);

//       ctx.fillStyle = "rgb(255, 255, 255)";
//       ctx.fillRect(0, 0, WIDTH, HEIGHT);

//       const barWidth = (WIDTH / analyzer.frequencyBinCount) * 4;
//       let barHeight;
//       let x = 0;

//       for (let i = 0; i < analyzer.frequencyBinCount; i++) {
//         barHeight = dataArray[i] * 2;

//         ctx.fillStyle = "rgb(" + (barHeight + 100) + ",50,50)";
//         ctx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight);

//         x += barWidth + 4;
//       }

//       requestAnimationFrame(draw);
//     };

//     draw();

//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         const source = audioContextRef.current.createMediaStreamSource(stream);
//         console.log("source", source);
//         source.connect(analyzer);

//         const mediaRecorder = new MediaRecorder(stream);
//         const recordedChunks = [];

//         mediaRecorder.ondataavailable = (e) => {
//           if (e.data.size > 0) {
//             recordedChunks.push(e.data);
//           }
//         };

//         mediaRecorder.onstop = () => {
//           const blob = new Blob(recordedChunks, { type: "audio/wav" }); // Adjust the type as per your audio format
//           const audioUrl = URL.createObjectURL(blob);
//           console.log("Audio URL:", audioUrl);
//           setBlobAudio(audioUrl);
//           // Now you can use the audio URL as needed, such as playing it or storing it
        
//         audioElement.onloadedmetadata = () => {
//           const duration = Math.ceil(audioElement.duration * 1000); // Convert duration to milliseconds
//           setRecordDuration(duration); // Set the duration for displaying in Editbutton
//         };

//         audioElement.play();
//       };

//       //   mediaRecorder.start();
//       //   setTimeout(() => {
//       //     mediaRecorder.stop();
//       //   }, 8000); // Stop recording after 5 seconds
//       // })
//       audioContextRef.current.mediaRecorder = mediaRecorder;
//       })
//       .catch((err) => {
//         console.error("Error accessing microphone:", err);
//       });

//     return () => {
//       audioContextRef.current.close();
//     };
//   }, []);

//   useEffect(() => {
//     let timerId;
//     if (isRecording) {
//       setStartTime(Date.now() - timeElapsed);
//       timerId = setInterval(() => {
//         setTimeElapsed(Date.now() - startTime);
//       }, 1000);
//     } else {
//       clearInterval(timerId);
//     }

//     return () => clearInterval(timerId);
//   }, [isRecording, startTime, timeElapsed]);

//   const toggleRecording = () => {
//     const audioContext = audioContextRef.current;
//     if (isRecording) {
//       audioContext.suspend();
//     } else {
//       audioContext.resume();
//     }
//     setIsRecording(!isRecording);
//   };

//   const handleStopClick = async () => {
//     setShowEditButton(true);
//     setIsRecording(false);
//     setTimeElapsed(Date.now() - startTime);
  
//     const audioContext = audioContextRef?.current;
//     audioContext.suspend(); // Suspend the audio context
  
//     const stream = audioContextRef.current.createMediaStreamDestination().stream;
//     if (!stream) {
//       console.error("Failed to capture audio stream.");
//       return;
//     }
  
//     // Convert the audio stream to a Blob
//     const recordedChunks = [];
//     const mediaRecorder = new MediaRecorder(stream);
//     mediaRecorder.ondataavailable = (e) => {
//       if (e.data.size > 0) {
//         recordedChunks.push(e.data);
//       }
//     };
//     mediaRecorder.onstop = async () => {
//       const blob = new Blob(recordedChunks, { type: "audio/mp3" }); // Adjust the type as per your audio format
//       setRecordedAudio(blob); // Save the recorded audio as Blob
  
//       // Create a new audio element dynamically for playback
//       const audioElement = new Audio();
//       const audioUrl = URL.createObjectURL(blob);
//       audioElement.src = audioUrl;
    
//       audioElement.onloadedmetadata = () => {
//         const duration = Math.ceil(audioElement.duration * 1000); // Convert duration to milliseconds
//         setRecordDuration(duration); // Set the duration for displaying in Editbutton
//       };
  
//       audioElement.play();
//     };
  
//     mediaRecorder.start();
//   };
  

//   return (
//     <div className={styles.voiceWebsite}>
//       {!showEditButton ? (
//         <section className={styles.emptyFrameParent}>
//           <img
//             className={styles.emptyFrameIcon}
//             alt=""
//             src="../images/empty-frame@2x.png"
//           />
//           <div className={styles.recordingText}>
//             <h1 className={styles.recording}>
//               {isRecording ? "Recording..." : "Recording Paused"}
//             </h1>
//           </div>
//           <div className={styles.innerFrame}>
//             <header className={styles.childFrame}>
//               <div className={styles.childFrameChild} />
//               <canvas ref={canvasRef} className={styles.childFrameItem} />
//             </header>
//             <div className={styles.placeholderText}>
//               {formatTime(timeElapsed)}
//             </div>
//           </div>
//           <div className={styles.vectorGroup}>
//             <div className={styles.frameParent}>
//               <img
//                 className={styles.frameChild}
//                 loading="lazy"
//                 alt=""
//                 src={
//                   isRecording
//                     ? "../images/pause1.svg"
//                     : "../images/group-3113.svg"
//                 }
//                 onClick={toggleRecording}
//               />
//               <div className={styles.rectangleParent}>
//                 <div className={styles.frameItem} />
//                 <img
//                   className={styles.childVectorIcon}
//                   alt=""
//                   src="../images/pause.svg"
//                 />
//               </div>
//             </div>
//             <div className={styles.pauseFrame}>
//               {isRecording ? (
//                 <div className={styles.pause} onClick={toggleRecording}>
//                   Pause
//                 </div>
//               ) : (
//                 <div className={styles.Play} onClick={toggleRecording}>
//                   Play
//                 </div>
//               )}

//               <div className={styles.stop} onClick={handleStopClick}>
//                 <img
//                   className={styles.frameChilds}
//                   loading="lazy"
//                   src="../images/stop.svg"
//                   alt="Stop"
//                 />{" "}
//                 {/* <span className={styles.stopText}>Stop</span> */}
//                 <div className={styles.stopText} onClick={toggleRecording}>
//                   Stop
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       ) : (
//         <Editbutton
//           blobAudio={blobAudio}
//           recordedAudio={recordedAudio} // Pass the recorded audio data
//         />
//       )}
//     </div>
//   );
// };

// const formatTime = (milliseconds) => {
//   const hours = Math.floor(milliseconds / (1000 * 60 * 60));
//   const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
//   const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
//   return (
//     `${hours < 10 ? "0" : ""}${hours}:` +
//     `${minutes < 10 ? "0" : ""}${minutes}:` +
//     `${seconds < 10 ? "0" : ""}${seconds}`
//   );
// };

// export default PlayButton;


