import styles from "./Section.module.css";

const Section = () => {
  return (
    <section className={styles.section}>
      <div className={styles.cloudStorageIcon}>
        <div className={styles.heading2}>How to Record Voice?</div>
        <div className={styles.withVoiceRecorder}>
          With Voice Recorder, Record your voice in just 3 simple steps.
        </div>
      </div>
      <div className={styles.compressVideoButton}>
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <img
            className={styles.frameItem}
            loading="lazy"
            alt=""
            src="../images/group-3339.svg"
          />
          <div className={styles.audioRecorderIcon}>
            <div className={styles.step1Click}>{`Step 1: Click Record `}</div>
            <div className={styles.uploadOrDragContainer}>
              <p className={styles.uploadOrDrag}>
                Upload or drag and drop a photo to our photo
              </p>
              <p className={styles.editorOnlineTo}>
                editor online to start editing.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.compressorFrameParent}>
            <div className={styles.compressorFrame} />
            <img
              className={styles.linkFrameIcon}
              loading="lazy"
              alt=""
              src="../images/vector-1.svg"
            />
          </div>
          <div className={styles.step2EditRecordingParent}>
            <div className={styles.step2Edit}>Step 2: Edit Recording</div>
            <div className={styles.adjustLightingColorContainer}>
              <p className={styles.adjustLightingColor}>
                Adjust lighting, color, and exposure, apply photo
              </p>
              <p className={styles.effectsAndCustomize}>
                effects, and customize your photo with text, sticker…
              </p>
            </div>
          </div>
          <div className={styles.frameInner} />
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.rectangleGroup}>
            <div className={styles.rectangleDiv} />
            <img
              className={styles.shapeIcon}
              loading="lazy"
              alt=""
              src="../images/shape.svg"
            />
          </div>
          <div className={styles.step3DownloadShareParent}>
            <div
              className={styles.step3Download}
            >{`Step 3: Download & share`}</div>
            <div className={styles.downloadYourEditedContainer}>
              <p className={styles.downloadYourEdited}>
                Download your edited photo in high-quality JPG,
              </p>
              <p className={styles.pngOrPdf}>
                PNG or PDF formats and share it instantly on social…
              </p>
            </div>
          </div>
          <div className={styles.frameChild1} />
        </div>
      </div>
    </section>
  );
};

export default Section;
