// import CopyRight from "./StyledComponents/CopyRight";
// import { Typography, Paper, Box, Stack,Divider,useTheme } from "@mui/material";
// import FlexBetween from "./StyledComponents/FlexBetween";
// import LanguageIcon from "@mui/icons-material/Language";
// import FooterLinks from "./StyledComponents/FooterLinks";

// const Footer = () => {
//     const theme = useTheme();

//   return (
//     <Box width="100%">
//       <Stack sx={{ display: { xs: "flex", md: "colum" } }}>
//         <Paper
//           sx={{
//             // marginTop: "0.5rem",
//             // padding: "1rem",
//             backgroundColor: "#727CF5",
//             borderRadius: "0px",
//           }}
//         >
//           {/* <CopyRight />
//           <FooterLinks /> */}
//           <Divider sx={{backgroundColor:"white", height:"2px"}}/>
//           <Box padding="14px 3%">
//             {/* <FlexBetween>
//               <Typography variant="body2" color="white" fontFamily={theme.typography.fontFamily}>
//                 © {new Date().getFullYear()} Video Compressor. All rights
//                 reserved.
//               </Typography>
//               <Stack
//                 direction="row"
//                 justifyContent="flex-end"
//                 alignItems="center"
//                 gap={5}
//               >
//                 <FlexBetween gap={3}>
//                   <Typography
//                     sx={{
//                       color: "white",
//                       fontSize: "14px",
//                       fontWeight: "300",
//                       wordWrap: "break-word",
//                     }}
//                   >
//                     About Us
//                   </Typography>
//                   <Typography
//                     sx={{
//                       color: "white",
//                       fontSize: "14px",
//                       fontWeight: "300",
//                       wordWrap: "break-word",
//                     }}
//                   >
//                     Terms of Use
//                   </Typography>
//                   <Typography
//                     sx={{
//                       color: "white",
//                       fontSize: "14px",
//                       fontWeight: "300",
//                       wordWrap: "break-word",
//                     }}
//                   >
//                     Privacy Policy
//                   </Typography>
//                   <Typography
//                     sx={{
//                       color: "white",
//                       fontSize: "14px",
//                       fontWeight: "300",
//                       wordWrap: "break-word",
//                     }}
//                   >
//                     Help
//                   </Typography>
//                 </FlexBetween>
//                 <Box>
//                   <FlexBetween>
//                     <LanguageIcon sx={{ color: "white", height: "16px" }} />
//                     <Typography
//                       sx={{
//                         color: "white",
//                         fontSize: "14px",
//                         fontWeight: "300",
//                         wordWrap: "break-word",
//                       }}
//                     >
//                       English
//                     </Typography>
//                   </FlexBetween>
//                 </Box>
//               </Stack>
//             </FlexBetween> */}
//           </Box>
//         </Paper>
//       </Stack>
//     </Box>
//   );
// };

// export default Footer;

// new
import React from "react";
import { styled } from "@mui/system";
import {
  Typography,
  Paper,
  Box,
  Stack,
  Divider,
  useTheme,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import FooterLinks from "./StyledComponents/FooterLinks";

const FooterPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#727CF5",
  borderRadius: "0px",
  width: "100%",
  overflowX: "hidden", // Add this to hide horizontal overflow
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "14px",
  fontWeight: "300",
  wordWrap: "break-word",
}));

const LanguageIconStyled = styled(LanguageIcon)(({ theme }) => ({
  color: "white",
  height: "16px",
}));

const Footer = () => {
  const theme = useTheme();
 

  return (
    <Box>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "stretch", md: "center" }}
      >
        <FooterPaper>
          {/* <FooterLinks /> */}
          <Divider sx={{ backgroundColor: "white", height: "2px" }} />
          <Box padding={{ xs: "14px 3%", md: "14px" }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "stretch", md: "center" }}
              spacing={{ xs: 1, md: 0 }}
            >
              <FooterText variant="body2" fontFamily={theme.typography.fontFamily}>
                © {new Date().getFullYear()} Video Compressor. All rights
                reserved.
              </FooterText>
              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Stack direction="row" spacing={3}>
                  <FooterText>About Us</FooterText>
                  <FooterText>Terms of Use</FooterText>
                  <FooterText>Privacy Policy</FooterText>
                  <FooterText>Help</FooterText>
                </Stack>
                <Box>
                  <Stack direction="row">
                    <LanguageIconStyled />
                    <FooterText>English</FooterText>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </FooterPaper>
      </Stack>
    </Box>
  );
};

export default Footer;
