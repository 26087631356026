import Header from "./Headerv";
import MergeAudio from "./MergeAudio";
import styles from "./ResizeVideoButton.module.css";

const ResizeVideoButton = () => {
  return (
    <div className={styles.resizeVideoButton}>
      <div className={styles.frameParent}>
        <Header
          playback="Sharing"
          propPadding="0rem var(--padding-8xs)"
          propFlexDirection="row"
          propMinWidth="unset"
          propPadding1="0rem 0rem 0rem var(--padding-9xs)"
        />
        <img
          className={styles.frameChild}
          loading="lazy"
          alt=""
          src="../images/group-3131@2x.png"
        />
        <Header
          playback="Transcription"
          propPadding="0rem var(--padding-11xs)"
          propFlexDirection="row"
          propMinWidth="unset"
          propPadding1="0rem 0rem 0rem var(--padding-6xs)"
        />
      </div>
      <div className={styles.frameGroup}>
        <img
          className={styles.frameItem}
          loading="lazy"
          alt=""
          src="../images/group-3129@2x.png"
        />
        <MergeAudio
          recording="Editing"
          propPadding="0rem 0rem var(--padding-20xl)"
          propWidth="unset"
          propPadding1="0rem 0rem 0rem var(--padding-6xs)"
        />
        <img
          className={styles.frameInner}
          loading="lazy"
          alt=""
          src="../images/group-3133@2x.png"
        />
      </div>
    </div>
  );
};

export default ResizeVideoButton;
