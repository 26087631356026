import React from "react";
import { Box, Grid, Typography, useTheme,useMediaQuery } from "@mui/material";

function FooterLinks() {
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();

  const linksData = [
    {
      category: "Compress Video",
      links: [
        { text: "Cut video", icon: "tool-sb-cut-video.svg" },
        { text: "Reverse video", icon: "tool-sb-reverse-video.svg" },
        { text: "Mute video", icon: "tool-sb-mute-video.svg" },
        { text: "Cut audio", icon: "tool-sb-cut-audio.svg" },
      ],
    },
    {
      category: "Merge video",
      links: [
        { text: "Rotate video", icon: "tool-sb-rotate-video.svg" },
        { text: "Crop video", icon: "tool-sb-crop-video.svg" },
        { text: "Add music to video", icon: "tool-sb-video-impose-audio.svg" },
        { text: "Merge audio", icon: "tool-sb-merge-audio.svg" },
      ],
    },
    {
      category: "Resize video",
      links: [
        { text: "Video maker", icon: "tool-sb-video-maker.svg" },
        { text: "Flip video", icon: "tool-sb-flip-video.svg" },
        { text: "Adjust video", icon: "tool-sb-adjust-video.svg" },
      ],
    },
    {
      category: "Video Compressor",
      links: [
        { text: "MP4 Converter", icon: null },
        { text: "MOV to MP4", icon: null },
        { text: "Video Converter", icon: null },
        { text: "Speed video", icon: null },
      ],
    },
    {
      category: "QR Code Creator",
      links: [
        { text: "Scan URL/Scan Website", icon: null },
        { text: "Scan Products, Scan ISBN", icon: null },
        { text: "Scan wifi and Connect", icon: null },
        { text: "Scan Location and Directions", icon: null },
      ],
    },
    {
      category: "Contact Info",
      links: [
        { text: "contact@raysaar.com", icon: "mail.svg" },
        { text: "+971 123456789", icon: "phone.svg" },
      ],
    },
  ];

  return (
    
      <Grid container spacing={2} p="3rem 5%">
      {linksData.map((category, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
          <Typography
            fontSize="21px"
            fontWeight="400"
            sx={{ wordWrap: "break-word" }}
            color="white"
            variant={theme.typography.h4}
          >
            {category.category}
          </Typography>
          {category.links.map((link, linkIndex) => (
            <Box key={linkIndex} mt={1} display="flex" justifyContent="flex-start">
              {link.icon && (
                <img
                  src={`../assets/${link.icon}`}
                  alt=""
                  style={{
                    color: "white",
                    height: isNonMobile ?"10%":"6%",
                    width: isNonMobile?"10%":"5%",
                    objectFit: "cover",
                  }}
                />
              )}
              <Typography
                position="relative"
                left={link.icon ? "5px" : "0"}
                bottom={link.icon ? "5px" : "0"}
                fontSize="16px"
                fontWeight="400"
                sx={{ wordWrap: "break-word" }}
                color="white"
                variant={theme.typography.h5}
              >
                {link.text}
              </Typography>
            </Box>
          ))}
        </Grid>
      ))}
    </Grid>

  );
}

export default FooterLinks;
