import React from "react";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";

function SwiperSlider() {  
  // const [slidesPerView, setSlidesPerView] = useState(5);
  const data = [
    {
      img: `../assets/myvideo (2).svg`,
    },
    {
      img: `../assets/largesize.svg`,
    },
    {
      img: `../assets/removeslected (2).svg`,
    },
    {
      img: `../assets/trime.svg`,
    },
    {
      img: `../assets/fast.svg`,
    },
    {
      img: `../assets/complete.svg`,
    },
    {
      img: `../assets/complete.svg`,
    },

    {
      img: `../assets/fast.svg`,
    },

  ];

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 600) {
  //       setSlidesPerView(1);
  //     } else {
  //       setSlidesPerView(5);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);

  //   // Cleanup function
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <Box 
    width={"100%"} 
    height={"100%"} 
    mt={{xs:32,sm:24,md:26,lg:22}}
    // mt={getMarginTop()}
    >
      <Box
        sx={{
          //   height: "200px",
          width: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //   border: "2px solid black",
        }}
      >
        <Box
          sx={{
            // borderRadius: 18,
            // mt: "30px",
            //   overflow: "hidden",
            //   position: "relative",
            ".swiper": {
              //   width:"340px",
              height: "38rem",
              padding: "2rem 0",
              borderRadius: "2rem",
            },
            ".swiper-slide": {
              width: "18rem",
              height: "33rem",
              position: "relative",
              borderRadius: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            // slidesPerView={5}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1.5,
            }}
            // pagination={{ el: ".swiper-pagination", clickable: true }}
            modules={[EffectCoverflow, Autoplay]}
            // autoplay={{ delay: 3000 }}
            loop={true}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  src={item.img}
                  alt="akash"
                  style={{
                    width: "18rem",
                    height: "33rem",
                    borderRadius: "1rem",
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
}

export default SwiperSlider;
