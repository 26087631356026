import { useEffect } from "react";
import GroupComponent from "../components/VoiceComponents/GroupComponent";
import styles from "./Section.module.css";


const Section = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className={styles.section}>
      <div className={styles.heading2HowToEditPhotosWrapper}>
        <div className={styles.heading2} data-animate-on-scroll>
          Why Online Voice Recorder
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <img
            className={styles.frameItem}
            loading="lazy"
            alt=""
            src="../images/group-3525@2x.png"
          />
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              <div className={styles.meetingOrLectureRecordingWrapper}>
                <div className={styles.meetingOrLecture}>
                  Meeting or Lecture Recording
                </div>
              </div>
              <div className={styles.captureImportantDiscussions}>
                Capture important discussions, presentations, or lectures for
                accurate note-taking and future reference.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rectangleGroup}>
          <div className={styles.frameInner} />
          <img
            className={styles.groupIcon}
            loading="lazy"
            alt=""
            src="../images/group-3528.svg"
          />
          <div className={styles.frameContainer}>
            <div className={styles.frameDiv}>
              <div className={styles.interviewsAndOralHistoryWrapper}>
                <div className={styles.interviewsAndOral}>
                  Interviews and Oral History
                </div>
              </div>
              <div className={styles.conductInterviewsOr}>
                Conduct interviews or record personal narratives for research,
                storytelling, or documentation purposes.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rectangleContainer}>
          <div className={styles.rectangleDiv} />
          <img
            className={styles.icon}
            loading="lazy"
            alt=""
            src="../images/10170116-1@2x.png"
          />
          <div className={styles.frameWrapper1}>
            <div className={styles.frameParent1}>
              <div className={styles.languageLearningAndPronunciWrapper}>
                <div className={styles.languageLearningAnd}>
                  Language Learning and Pronunciation Practice
                </div>
              </div>
              <div className={styles.improveSpeakingAnd}>
                Improve speaking and listening skills in a foreign language by
                recording and reviewing pronunciation.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.groupDiv}>
          <div className={styles.frameChild1} />
          <img
            className={styles.groupIcon1}
            loading="lazy"
            alt=""
            src="../images/group.svg"
          />
          <div className={styles.frameWrapper2}>
            <div className={styles.frameParent2}>
              <div className={styles.voiceMemosAndPersonalRefleWrapper}>
                <div className={styles.voiceMemosAnd}>
                  Voice Memos and Personal Reflections
                </div>
              </div>
              <div className={styles.useAsA}>
                {" "}
                Use as a personal journal or diary to record thoughts,
                reflections, or memorable moments for self-expression.
              </div>
            </div>
          </div>
        </div>
        <GroupComponent />
      </div>
    </div>
  );
};

export default Section;
