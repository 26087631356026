import { useEffect } from 'react';

const LeaveConfirmation = () => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to leave this website?\n\nCustom message: Your unsaved changes may be lost.';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return null;
};

export default LeaveConfirmation;


