import React from "react";
import AddFileButton from "../FileUploadingPageComponents/AddFileButton";
import { Grid, Paper, Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "../StyledComponents/FlexBetween";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import VideoIcon from "../FileUploadingPageComponents/VideoIcon";

const Item = (props) => (
  <Paper
    sx={{
      justifyContent: "flex-start",
      padding: "16px 1rem",
      borderRadius: "0px",
      height: "60px",
      ...props.sx,
    }}
  >
    {props.children}
  </Paper>
);

function FileAddingComponents({
  multipleFilesSelected,
  handleRemoveFile,
  handleUploadAndCompression,
  onFilesSelected,
}) {
  const theme = useTheme();
  return (
    <>
      <AddFileButton onFilesSelected={onFilesSelected} />
      <Grid
        container
        box-sizing="border-box"
        border="1px solid #F6F6F6"
        borderRadius="5px"
      >
        {multipleFilesSelected.length > 0 &&
          multipleFilesSelected.map((file, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} border="none">
                <Item
                  sx={{
                    border: "none",
                    color: "rgba(0, 0, 0, 0.50)",
                    boxShadow: "none",
                  }}
                >
                  <Box>
                    <img
                      src="../assets/video-icon.svg"
                      alt=""
                      style={{
                        color: "white",
                        height: "25px",
                        width: "50px",
                        objectFit: "cover",
                        borderRadius: "5px",
                        // border:"2px solid rgb(238,174,202)"
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "400",
                        wordWrap: "break-word",
                        ml:"4px"
                      }}
                      variant={theme.typography.h5}
                    >
                      {file.name}
                    </Typography>
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={5} border="none">
                <Item
                  sx={{
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                    variant={theme.typography.h5}
                  >
                    {/* (data.compressedFileSize / (1024 * 1024)).toFixed(2) */}
                    {(file.size / (1024 * 1024)).toFixed(2)}MB
                  </Typography>
                </Item>
              </Grid>

              <Grid item xs={5} border="none">
                <Item
                  sx={{
                    color: "rgba(0, 0, 0, 0.50)",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <Box>
                    <FlexBetween>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "400",
                          wordWrap: "break-word",
                        }}
                        variant={theme.typography.h5}
                      >
                        Output:
                      </Typography>
                      <Box
                        textAlign="center"
                        border="1px rgba(0, 0, 0, 0.50) solid"
                        borderRadius="5px"
                        color="blue"
                        p="2px 2px"
                      >
                        <Typography>MP4</Typography>
                      </Box>
                    </FlexBetween>
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={2} border="none">
                <Item
                  sx={{
                    color: "rgba(0, 0, 0, 0.50)",
                    border: "none",
                    boxShadow: "none",
                  }}
                  // onClick={handleRemoveFile(index)}
                  // onClick={() => removeFile(index)}
                >
                  <Box>
                    <CancelRoundedIcon
                      sx={{
                        fontSize: "26px",
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          cursor: "pointer",
                          color: "red",
                          transform: "scale(1.2)",
                        },
                      }}
                      onClick={() => handleRemoveFile(index)}
                    />
                  </Box>
                </Item>
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={12} border="none">
          <Item
            sx={{
              background: "#EEEEEE",
              color: "rgba(0, 0, 0, 0.50)",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Typography
              variant={theme.typography.h6}
              sx={{
                wordWrap: "break-word",
                fontWeight: "400",
                color: "#393E46",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Added {multipleFilesSelected.length} Files
            </Typography>
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          border="none"
          // onClick={handleUploadAndCompression(multipleFilesSelected)}
          onClick={() => handleUploadAndCompression(multipleFilesSelected)}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Item
            sx={{
              background: "#727cf5",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Typography
              variant={theme.typography.h6}
              sx={{
                wordWrap: "break-word",
                fontWeight: "400",
                color: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Compress Now
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

export default FileAddingComponents;
