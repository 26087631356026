import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, styled } from "@mui/material";
// import Card from "./Card";


const SlickArrow = styled("img")(({ theme, isLeft, isRight }) => ({
  position: "absolute",
  top: "50%",
  padding:"10px",
  transform: "translateY(-50%)",
  zIndex: 1,
  backgroundColor: "rgba(255, 255, 255, 1)",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  [isLeft ? "left" : "right"]: "-16px", // Position the arrow left or right
  }));

function CardSlider() {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <SlickArrow src="../assets/left-arrow.svg" isLeft alt="nextArrow" {...props} />
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <SlickArrow src="../assets/right-arrow.svg" isRight alt="nextArrow" {...props} />
  );

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: true, // Remove default arrows
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
  };

  const data = [
    {
      heading: `Reducing File Size`,
      img: `../assets/reduce file size.jpg`,
      content: `Compressing videos allows for easier sharing via email, messaging apps, or social media platforms, especially when dealing with large files that may exceed file size limits.`,
    },
    {
      heading: `Optimizing for Uploads`,
      img: `../assets/optimize for upload.jpg`,
      content: `Compressing videos before uploading them to websites, blogs, or online platforms helps to reduce loading times and improve user experience, particularly on devices with slower internet connections.`,
    },
    {
      heading: `Saving Storage Space`,
      img: `../assets/save stroage space.jpg`,
      content: `Compressing videos before storing them on your device or cloud storage services conserves storage space, allowing you to keep more videos without running out of storage capacity.`,
    },
    {
      heading: `Enhancing Playback`,
      img: `../assets/mobile-card.jpg`,
      content: `Compressing videos for playback on mobile devices reduces buffering and ensures smoother playback, making it easier to watch videos on smartphones and tablets, even with limited network bandwidth.`,
    },
    {
      heading: `Maintaining Quality`,
      img: `../assets/maintaing quality.jpg`,
      content: `Compressing videos while preserving visual quality is ideal for archiving purposes, enabling you to store videos for long-term preservation without sacrificing too much in terms of video quality.`,
    },
  ];
  return (
    <>
      <Slider {...settings}>
        {data.map(({ heading, img, content }) => (
          // <Card heading={heading} img={img} content={content}/>
          <Card sx={{ maxWidth: "25rem",height:"360px" }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="160px"
                src={img}
                alt="green iguana"
                sx={{
                    objectFit:"cover"
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {heading}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {content}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Slider>
    </>
  );
}

export default CardSlider;
