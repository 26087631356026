import React from "react";
import { Box, Typography, useTheme, useMediaQuery, Stack,SvgIcon } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import HdrAutoSelectIcon from "@mui/icons-material/HdrAutoSelect";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";


function HowToCompressComponent() {    
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1024px)");

  const data = [
    {
      heading: `Select the Videos`,
      icons: <LibraryAddCheckIcon fontSize="large" sx={{
        color:"rgba(194,55,137,1)"
      }}/>,
      
    },
    {
      heading: `Choose your desired Quality`,
      icons: <HdrAutoSelectIcon fontSize="large" sx={{
        color:"rgba(194,55,137,1)"
      }}/>,
      
    },
    {
      heading: `Compress and Download`,
      icons:<DownloadIcon fontSize="large" sx={{
        color:"rgba(194,55,137,1)"
      }}/>,
     
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //  width:isNonMobileScreens?"31.75rem":"21.75rem"
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            lineHeight: 1.2,
            fontWeight: 800,
            fontSize: isNonMobileScreens ? "2.687rem" : "24px",
            // fontSize:{xs:"24px",sm:"2.687rem",md:"2.687rem",lg:"2.687rem"},
            background:
              "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontFamily: "inherit",
            // width:{xs:"90%",sm:"31.75rem",md:"31.75rem",lg:"31.75rem"}
          }}
        >
          How to compress videos?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "inherit",
            fontSize: "1.125rem",
            lineHeight: "1.406rem",
            wordWrap: "break-word",
            mt: "1rem",
            // width:isNonMobileScreens?"28.75rem":"21.75rem"
            width: {
              xs: "90%",
              sm: "90%",
              md: "35.75rem",
              lg: "31.75rem",
            },
          }}
        >
          Tech Tool video compressor will simplify the compression process into
          three easy steps:
        </Typography>
      </Stack>
      <Box
        sx={{
          padding: "1rem 3%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {data.map((item, index) => (
          <Box
            key={index}
            sx={{
              border: "2px solid rgba(101, 101, 101, .1)",
              width: "360px",
              borderRadius: "1rem",
              height: "18rem",
              margin: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover":{
                transform:"translateY(-2px)",
                 boxShadow:"0 20px 20px 0 rgba(2,19,79,.09)"
              }
            }}
          >
            <Stack sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
              <Box>{item.icons}</Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "inherit",
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  lineHeight: "1.406rem",
                  letterSpacing: ".14px",
                  mt:"8px"
                }}
              >
                {item.heading}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default HowToCompressComponent;
