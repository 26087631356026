import React from "react";
import { Box, Typography, useTheme,Stack,Button,useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";


function WelcomeComponents() {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1024px)");
  return (
    <>
      <Box
        sx={{
          padding: "0rem 5%",
          display: "flex",
          flexWrap: "wrap",
          pt: "6.5rem",
          flexDirection:isNonMobileScreens?"row":"column"
        }}
      >
        <Box
          sx={{
            width:isNonMobileScreens?"60%":"90%",
          }}
        >
          <Stack>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 800,
                fontSize: isNonMobileScreens?"2.687rem":"24px",
                lineHeight:1.2
              }}
              variant={theme.typography.h2}
            >
              Welcome to TechTool: Discover the Best Tools for Mobile and Web!
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                mt:1.2
              }}
              variant={theme.typography.h6}
            >
              At TechTool, we empower you with cutting-edge solutions that
              enhance your digital experience. Explore our versatile utility
              apps designed for both mobile and web platforms, and discover the
              convenience of seamless video compression and crystal-clear voice
              recording.
            </Typography>
            <Button sx={{
                background:"linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(187,75,75,1) 57%, rgba(252,176,69,1) 100%)",
                color:"#fff",
                width:"120px",
                mt:1.2,
                borderRadius:"30px"
            }}>
                Signup
            </Button>
          </Stack>
        </Box>
        <Box
          sx={{
            width: isNonMobileScreens?"40%":"90%",
            display: "flex",
            justifyContent: isNonMobileScreens?"flex-end":"center",
          }}
        >
          <img
            src="../assets/group-3352.svg"
            alt="Logo"
            style={{
              color: "white",
              height: "100%",
              width: "80%",
              objectFit: "cover",
            }}
          />
          {/* <motion.img
            src="../assets/group-3352.svg"
            alt="Logo"
            style={{
              color: "white",
              height: "100%",
              width: "80%",
              objectFit: "cover",
            }}
            animate={{ rotate: 360 }} // Add rotation animation
            transition={{ duration: 10, repeat: Infinity, ease: "linear" }} // Slow rotation animation
          /> */}
        </Box>
      </Box>
    </>
  );
}

export default WelcomeComponents;
