import React from "react";
import { Box, Typography, Button, styled, useTheme,useMediaQuery } from "@mui/material";
import FlexCenter from "./StyledComponents/FlexCenter";
import FlexBetween from "./StyledComponents/FlexBetween";

const StyledDivPricingPlan = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  border: `3px solid ${theme.palette.background.paper}`,
  borderRadius: theme.shape.borderRadius,
  height: "475px",
  position: "relative",
  width: "calc(100% - 16px)",
  boxSizing: "border-box",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
    boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.20)",
  },
}));

const StyledHeadingBasic = styled(Typography)(({ theme }) => ({
  color: "#282f3a",
  // fontFamily: "Overpass-SemiBold, Helvetica",
  fontSize: "30px",
  fontWeight: 600,
  height: "36px",
  left: "21px",
  letterSpacing: 0,
  lineHeight: "36px",
  position: "absolute",
  top: "12px",
  whiteSpace: "nowrap",
  width: "77px",
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: "#727cf5",
  // fontFamily: "Overpass-Black, Helvetica",
  fontSize: "40px",
  fontWeight: 900,
  height: "26px",
  left: "21px",
  letterSpacing: 0,
  lineHeight: "26px",
  position: "absolute",
  top: "68px",
  whiteSpace: "nowrap",
  width: "110px",
}));

const StyledTextWrapper = styled(Typography)(({ theme }) => ({
  color: "#686868",
  // fontFamily: "Overpass-Regular, Helvetica",
  fontSize: "16px",
  fontWeight: 400,
  height: "20px",
  left: "21px",
  letterSpacing: 0,
  lineHeight: "24px",
  position: "absolute",
  top: "100px",
  whiteSpace: "nowrap",
  width: "184px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffffff",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "4px",
  height: "40px",
  left: "21px",
  position: "absolute",
  top: "140px",
  width: "200px",
}));

const StyledListItem = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.divider,
  borderTopStyle: "solid",
  borderTopWidth: "1px",
  height: "55px",
  left: "21px",
  position: "absolute",
  width: "200px",
}));

const StyledSpan = styled(Box)(({ theme }) => ({
  height: "24px",
  left: "23px",
  position: "absolute",
  top: "12px",
  width: "159px",
}));

const StyledStrongGB = styled(Typography)(({ theme }) => ({
  color: "#282f3a",
  // fontFamily: "Overpass-Bold, Helvetica",
  // fontSize: "12px",
  fontWeight: 400,
  height: "17px",
  left: 0,
  letterSpacing: 0,
  // lineHeight: "24px",
  position: "absolute",
  top: "1px",
  whiteSpace: "nowrap",
  width: "160px",
}));

const StyledTextWrapper2 = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));

const StyledTextWrapper3 = styled(Typography)(({ theme }) => ({
  // fontFamily: "Overpass-Light, Helvetica",
  fontWeight: 300,
}));

const StyledImage = styled("img")({
  height: "17px",
  left: 0,
  position: "absolute",
  top: "16px",
  width: "17px",
});

const StyledListItem3 = styled(StyledListItem)({ top: "200px" });
const StyledListItem4 = styled(StyledListItem)({ top: "245px" });
const StyledListItem5 = styled(StyledListItem)({ top: "290px" });
const StyledListItem6 = styled(StyledListItem)({ top: "335px" });
const StyledListItem7 = styled(StyledListItem)({ top: "380px" });
const StyledListItem8 = styled(StyledListItem)({ top: "425px" });

const StyledOverlapGroupWrapper = styled(Box)({
  height: "24px",
  left: "23px",
  position: "absolute",
  top: "16px",
  width: "228px",
});

const StyledOverlapGroup = styled(Box)({
  height: "17px",
  position: "relative",
  top: "2px",
  width: "222px",
});

const StyledStrong = styled(Typography)({
  color: "#282f3a",
  fontFamily: "Overpass-Bold, Helvetica",
  fontSize: "14px",
  fontWeight: 400,
  height: "17px",
  left: 0,
  letterSpacing: 0,
  lineHeight: "24px",
  position: "absolute",
  top: 0,
  whiteSpace: "nowrap",
  width: "208px",
});

const StyledSVG2 = styled("img")({
  height: "14px",
  left: "208px",
  position: "absolute",
  top: "3px",
  width: "14px",
});

// const StyledListItem2 = styled(StyledListItem)({ top: "354px" });

const PricingPlan = () => {
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <FlexCenter>
          <Typography
            sx={{
              color: "black",
              fontSize: isNonMobile ?"36px":"28px",
              fontWeight: "500",
              wordWrap: "break-word",
              padding:"1rem"
            }}
            variant={theme.typography.fontFamily}
          >
            Compress <span style={{ color: "#727CF5" }}> more for less</span> ?
          </Typography>
        </FlexCenter>
        <Box width="100%" padding={isNonMobile?"1rem 0%":"1rem 4%"} borderRadius="10px" mt="2%">
          <Box gap={2} display={{ md: "flex", width: "100%" }}>
            <StyledDivPricingPlan >
              <StyledHeadingBasic variant={theme.typography.fontFamily}>
                Basic
              </StyledHeadingBasic>
              <StyledHeading variant={theme.typography.fontFamily}>
                $9.99
              </StyledHeading>
              <StyledTextWrapper variant={theme.typography.fontFamily}>
                / Month (cancel any time)
              </StyledTextWrapper>
              <StyledButton variant="outlined">
                <Typography
                  sx={{
                    color: "#727cf5",
                    fontSize: "16px",
                    fontWeight: 400,
                    height: "20px",
                    letterSpacing: 0,
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    // width: "40px",
                  }}
                  variant={theme.typography.fontFamily}
                >
                  Continue
                </Typography>
                {/* <img
                  className="SVG"
                  alt="Svg"
                  src="../assets/arrow.svg"
                  sx={{
                    height: "18px",
                    left: "px",
                    position: "absolute",
                    top: "20px",
                    width: "18px",
                  }}
                /> */}
              </StyledButton>
              {/* ... Additional components, converted similarly ... */}

              {/* <StyledListItem>
                <StyledSpan>
                  <StyledStrongGB>
                    <StyledTextWrapper2>1.5 GB</StyledTextWrapper2>
                    <StyledTextWrapper3> maximum file size</StyledTextWrapper3>
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="image.svg" />
              </StyledListItem> */}
              {/* <StyledListItem2>
                <StyledImage alt="Svg" src="SVG-2.svg" />
                <StyledOverlapGroupWrapper>
                  <StyledOverlapGroup>
                    <StyledStrong>
                      <StyledTextWrapper2>1500</StyledTextWrapper2>
                      <StyledTextWrapper3>
                        {" "}
                        conversion minutes/Month{" "}
                      </StyledTextWrapper3>
                    </StyledStrong>
                    <StyledSVG2 alt="Svg" src="SVG-3.svg" />
                  </StyledOverlapGroup>
                </StyledOverlapGroupWrapper>
              </StyledListItem2> */}
              <StyledListItem3>
                {/* Similar conversion for ListItem3 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography sx={{ wordWrap: "break-word" }}>
                      1.5 GB maximum file size
                    </Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem3>
              <StyledListItem4>
                {/* Similar conversion for ListItem4 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>1500 conversion min/Month</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem4>
              <StyledListItem5>
                {/* Similar conversion for ListItem5 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography sx={{ wordWrap: "break-word" }}>
                      25 conversions at a time
                    </Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem5>
              <StyledListItem6>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>No Ads</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem6>
              <StyledListItem7>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>Merge 40 files at a time</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem7>
              <StyledListItem8>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>High priority</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem8>
            </StyledDivPricingPlan>

            {/* two */}
            <StyledDivPricingPlan  mt={isNonMobile?"":"30px"}>
              <StyledHeadingBasic variant={theme.typography.fontFamily}>
                Standard
              </StyledHeadingBasic>
              <StyledHeading variant={theme.typography.fontFamily}>
                $14.99
              </StyledHeading>
              <StyledTextWrapper variant={theme.typography.fontFamily}>
                / Month (cancel any time)
              </StyledTextWrapper>
              <StyledButton variant="outlined">
                <Typography
                  sx={{
                    color: "#727cf5",
                    fontSize: "16px",
                    fontWeight: 400,
                    height: "20px",
                    letterSpacing: 0,
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    width: "68px",
                  }}
                  variant={theme.typography.fontFamily}
                >
                  Continue
                </Typography>
                {/* <img
                  className="SVG"
                  alt="Svg"
                  src="../assets/arrow.svg"
                  sx={{
                    height: "18px",
                    left: "px",
                    position: "absolute",
                    top: "20px",
                    width: "18px",
                  }}
                /> */}
              </StyledButton>
              {/* ... Additional components, converted similarly ... */}

              {/* <StyledListItem>
                <StyledSpan>
                  <StyledStrongGB>
                    <StyledTextWrapper2>1.5 GB</StyledTextWrapper2>
                    <StyledTextWrapper3> maximum file size</StyledTextWrapper3>
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="image.svg" />
              </StyledListItem> */}
              {/* <StyledListItem2>
                <StyledImage alt="Svg" src="SVG-2.svg" />
                <StyledOverlapGroupWrapper>
                  <StyledOverlapGroup>
                    <StyledStrong>
                      <StyledTextWrapper2>1500</StyledTextWrapper2>
                      <StyledTextWrapper3>
                        {" "}
                        conversion minutes/Month{" "}
                      </StyledTextWrapper3>
                    </StyledStrong>
                    <StyledSVG2 alt="Svg" src="SVG-3.svg" />
                  </StyledOverlapGroup>
                </StyledOverlapGroupWrapper>
              </StyledListItem2> */}
              <StyledListItem3>
                {/* Similar conversion for ListItem3 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>2 GB maximum file size</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem3>
              <StyledListItem4>
                {/* Similar conversion for ListItem4 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>1800 conversion min/Month</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem4>
              <StyledListItem5>
                {/* Similar conversion for ListItem5 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>50 conversions at a time</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem5>
              <StyledListItem6>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>No Ads</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem6>
              <StyledListItem7>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>Merge 60 files at a time</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem7>
              <StyledListItem8>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>High priority</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem8>
            </StyledDivPricingPlan>

            {/* end of two */}

            {/* three */}
            <StyledDivPricingPlan  mt={isNonMobile?"":"30px"}>
              <StyledHeadingBasic variant={theme.typography.fontFamily}>
                Pro
              </StyledHeadingBasic>
              <StyledHeading variant={theme.typography.fontFamily}>
                $25.99
              </StyledHeading>
              <StyledTextWrapper variant={theme.typography.fontFamily}>
                / Month (cancel any time)
              </StyledTextWrapper>
              <StyledButton variant="outlined">
                <Typography
                  sx={{
                    color: "#727cf5",
                    fontSize: "16px",
                    fontWeight: 400,
                    height: "20px",
                    letterSpacing: 0,
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    width: "68px",
                  }}
                  variant={theme.typography.fontFamily}
                >
                  Continue
                </Typography>
                {/* <img
                  className="SVG"
                  alt="Svg"
                  src="../assets/arrow.svg"
                  sx={{
                    height: "18px",
                    left: "px",
                    position: "absolute",
                    top: "20px",
                    width: "18px",
                  }}
                /> */}
              </StyledButton>
              {/* ... Additional components, converted similarly ... */}

              {/* <StyledListItem>
                <StyledSpan>
                  <StyledStrongGB>
                    <StyledTextWrapper2>1.5 GB</StyledTextWrapper2>
                    <StyledTextWrapper3> maximum file size</StyledTextWrapper3>
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="image.svg" />
              </StyledListItem> */}
              {/* <StyledListItem2>
                <StyledImage alt="Svg" src="SVG-2.svg" />
                <StyledOverlapGroupWrapper>
                  <StyledOverlapGroup>
                    <StyledStrong>
                      <StyledTextWrapper2>1500</StyledTextWrapper2>
                      <StyledTextWrapper3>
                        {" "}
                        conversion minutes/Month{" "}
                      </StyledTextWrapper3>
                    </StyledStrong>
                    <StyledSVG2 alt="Svg" src="SVG-3.svg" />
                  </StyledOverlapGroup>
                </StyledOverlapGroupWrapper>
              </StyledListItem2> */}
              <StyledListItem3>
                {/* Similar conversion for ListItem3 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>5 GB maximum file size</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem3>
              <StyledListItem4>
                {/* Similar conversion for ListItem4 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>3500 conversion min/Month</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem4>
              <StyledListItem5>
                {/* Similar conversion for ListItem5 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>100 conversions at a time</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem5>
              <StyledListItem6>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>No Ads</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem6>
              <StyledListItem7>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>Merge 100 files at a time</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem7>
              <StyledListItem8>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>High priority</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem8>
            </StyledDivPricingPlan>
            {/* end of three */}
            {/* four */}
            <StyledDivPricingPlan  mt={isNonMobile?"":"30px"}>
              <StyledHeadingBasic variant={theme.typography.fontFamily}>
                24-h Pass
              </StyledHeadingBasic>
              <StyledHeading variant={theme.typography.fontFamily}>
                $12.99
              </StyledHeading>
              <StyledTextWrapper variant={theme.typography.fontFamily}>
                / One Time
              </StyledTextWrapper>
              <StyledButton variant="outlined">
                <Typography
                  sx={{
                    color: "#727cf5",
                    fontSize: "16px",
                    fontWeight: 400,
                    height: "20px",
                    letterSpacing: 0,
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    width: "68px",
                  }}
                  variant={theme.typography.fontFamily}
                >
                  Continue
                </Typography>
                {/* <img
                  className="SVG"
                  alt="Svg"
                  src="../assets/arrow.svg"
                  sx={{
                    height: "18px",
                    left: "px",
                    position: "absolute",
                    top: "20px",
                    width: "18px",
                  }}
                /> */}
              </StyledButton>
              {/* ... Additional components, converted similarly ... */}

              {/* <StyledListItem>
                <StyledSpan>
                  <StyledStrongGB>
                    <StyledTextWrapper2>1.5 GB</StyledTextWrapper2>
                    <StyledTextWrapper3> maximum file size</StyledTextWrapper3>
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="image.svg" />
              </StyledListItem> */}
              {/* <StyledListItem2>
                <StyledImage alt="Svg" src="SVG-2.svg" />
                <StyledOverlapGroupWrapper>
                  <StyledOverlapGroup>
                    <StyledStrong>
                      <StyledTextWrapper2>1500</StyledTextWrapper2>
                      <StyledTextWrapper3>
                        {" "}
                        conversion minutes/Month{" "}
                      </StyledTextWrapper3>
                    </StyledStrong>
                    <StyledSVG2 alt="Svg" src="SVG-3.svg" />
                  </StyledOverlapGroup>
                </StyledOverlapGroupWrapper>
              </StyledListItem2> */}
              <StyledListItem3>
                {/* Similar conversion for ListItem3 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>1.5 GB maximum file size</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem3>
              <StyledListItem4>
                {/* Similar conversion for ListItem4 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>1500 conversion min/Month</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem4>
              <StyledListItem5>
                {/* Similar conversion for ListItem5 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>50 conversions at a time</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem5>
              <StyledListItem6>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>No Ads</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem6>
              <StyledListItem7>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>Merge 50 files at a time</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem7>
              <StyledListItem8>
                {/* Similar conversion for ListItem6 */}
                <StyledSpan>
                  <StyledStrongGB>
                    <Typography>High priority</Typography>
                    {/* <StyledTextWrapper2>1.5 GB</StyledTextWrapper2> */}
                    {/* <StyledTextWrapper3> maximum file size</StyledTextWrapper3> */}
                  </StyledStrongGB>
                </StyledSpan>
                <StyledImage alt="Svg" src="../assets/right.svg" />
              </StyledListItem8>
            </StyledDivPricingPlan>
            {/* end of four */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PricingPlan;
